<template>
	<div>
		<v-container>
			<alert-bar :snackbar="alertInfo" />
			<v-breadcrumbs :items="breadcrumbs" divider=""></v-breadcrumbs>
			<data-table />
		</v-container>
	</div>
</template>

<script>
// data table
import DataTable from "@/components/data-tables/Users";
// alert bar for message
import AlertBar from "@/components/AlertBar";

export default {
	name: "Users",
	data() {
		return {
			breadcrumbs: [
				{
					text: " ",
					disabled: true
				}
			]
		};
	},
	computed: {
		alertInfo() {
			return this.$store.getters.USER_STATUS;
		}
	},
	components: {
		DataTable,
		AlertBar
	}
};
</script>
