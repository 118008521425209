<template>
	<div>
		<v-container>
			<v-breadcrumbs :items="breadcrumbs" divider=""></v-breadcrumbs>
			Home page
		</v-container>
	</div>
</template>

<script>
// data table

export default {
	name: "Home",
	data() {
		return {
			breadcrumbs: [
				{
					text: " ",
					disabled: true
				}
			]
		};
	},
	components: {
		//
	}
};
</script>
