import axios from "axios";

export default {
	state: {
		practice: {},
		practices: [],
		practiceStatus: {}, // response obj
		practicePagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		}, // pagination
		elements: [],
		elementStatus: {} // response obj
	},
	mutations: {
		SET_PRACTICES_TO_STATE: (state, practices) => {
			state.practices = practices;
		},
		SET_PRACTICE_TO_STATE: (state, practice) => {
			state.practices.push(practice);
		},
		SET_PRACTICE: (state, practice) => {
			state.practice = practice
		},
		UPDATE_PRACTICE_FROM_STATE: (state, practice) => {
			const index = state.practices.findIndex(r => r.id === practice.id);
			if (index !== -1) state.practices.splice(index, 1, practice);
		},
		DELETE_PRACTICE_FROM_STATE: (state, practice) => {
			state.practices = state.practices.filter(r => practice.id !== r.id);
		},
		// set response
		SET_PRACTICE_RESPONSE_TO_STATE: (state, response) => {
			state.practiceStatus = response;
		},
		// set metadata for pagination
		SET_PRACTICES_PAGINATION_TO_STATE: (state, response) => {
			state.practicePagination.page = response.page;
			state.practicePagination.rowsPerPage = response.per_page;
			state.practicePagination.totalItems = response.items;
		},
		// elements
		SET_ELEMENTS_TO_STATE: (state, elements) => {
			state.elements = elements;
		},
		SET_ELEMENT_TO_STATE: (state, element) => {
			state.elements.push(element);
		},
		DELETE_ELEMENT_FROM_STATE: (state, element) => {
			state.elements = state.elements.filter(r => element.id !== r.id);
		},
		// set response
		SET_ELEMENT_RESPONSE_TO_STATE: (state, response) => {
			state.elementStatus = response;
		}
	},
	actions: {
		// return a list of practices
		GET_PRACTICES_FROM_API({ commit }, params) {

			let concat = null

			if (params !== null) {
				if (params.elements) {
					concat = `?type=elements`
				} else {
					concat = `?include_metadata=enable&page=${ params.pagination.page }&per_page=${ params.pagination.itemsPerPage }`;
				}
			} else {
				concat = '';
			}

			return axios(`/practices${ concat }`, {
				method: "GET"
			})
			.then(practices => {
				commit("SET_PRACTICES_TO_STATE", practices.data.items);

				if (concat) {
					commit(
						"SET_PRACTICES_PAGINATION_TO_STATE",
						practices.data._metadata.pagination
					);
				}
			})
			.catch(error => {
				console.log(error.response.data); // debug
			});
		},
		// return theory by id
		GET_PRACTICE_FROM_API({ commit }, id) {
			return axios(`practices/${ id }`, {
				method: "GET"
			})
				.then(items => {
					commit("SET_PRACTICE", items.data);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new practice
		CREATE_PRACTICE_TO_API({ commit }, practice) {
			return axios("/practices", {
				method: "POST",
				data: practice
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_PRACTICE_TO_STATE", response.data);

						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Практика успешно добавлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 400) {
						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.data.code === 401) {
						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update practice
		UPDATE_PRACTICE_FROM_API({ commit }, practice) {
			return axios("/practices/" + practice.id, {
				method: "PUT",
				data: practice
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_PRACTICE_FROM_STATE", response.data);

						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Практика успешно обновлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 400) {
						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.data.code === 401) {
						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete practice
		DELETE_PRACTICE_FROM_API({ commit }, practice) {
			return axios("/practices/" + practice.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_PRACTICE_FROM_STATE", practice);

						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Практика успешно удалена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PRACTICE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// pin new exercise
		PIN_ELEMENT_TO_API({ commit }, params) {
			console.log(params);
			return axios(`practices/${ params.practice_id }/elements`, {
				method: "POST",
				data: params.obj
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_ELEMENT_TO_STATE", response.data);

						commit("SET_ELEMENT_RESPONSE_TO_STATE", {
							text: "Элемент успешно прикреплен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_ELEMENT_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_ELEMENT_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_ELEMENT_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete exercise
		UNPIN_ELEMENT_FROM_API({ commit }, params) {
			console.log(params)
			return axios(`practices/${ params.practice_id }/elements/${ params.element_id }`, {
				method: "DELETE",
				data: params.obj
			})
				.then(response => {
					if (response.status === 204 || response.status === 200) {
						commit("DELETE_ELEMENT_FROM_STATE", response.data);

						commit("SET_ELEMENT_RESPONSE_TO_STATE", {
							text: "Упражнение успешно откреплено",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data) // debug
					if (error.response.status === 401) {
						commit("SET_ELEMENT_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_ELEMENT_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		PRACTICE(state) {
			return state.practice || {}
		},
		PRACTICES(state) {
			return state.practices || [];
		},
		PRACTICE_STATUS(state) {
			return state.practiceStatus;
		},
		PRACTICES_PAGINATION(state) {
			return state.practicePagination;
		}
	}
};
