<template>
	<v-data-table
		:loading="loading"
		loading-text="Пожалуйста подождите..."
		:headers="headers"
		:items="FEEDBACKS"
		:footer-props="{
      itemsPerPageOptions: [5, 10, 15, 30],
      itemsPerPageText: 'Строк на странице',
      itemsPerPageAllText: 'Все',
      pageText: '{0} - {1} из {2}',
    }"
		:options.sync="pagination"
		:server-items-length="pagination.totalItems"
		@update:options="initialize"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Обратная связь</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" scrollable max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
							Создать обратную связь
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>
						<v-divider></v-divider>

						<v-card-text class="pt-5">
							<v-select
								:items="categories"
								color="info"
								v-model="editedItem.category_id"
								item-value="id"
								item-text="name"
								label="Категория *"
								:rules="[rules.required]"
								required
								outlined
								dense
							></v-select>
							<v-text-field
								color="info"
								v-model="editedItem.title"
								label="Заголовок *"
								:rules="[rules.required]"
								required
								outlined
								dense
							></v-text-field>
							<v-textarea
								color="info"
								v-model="editedItem.message"
								label="Сообщение *"
								outlined
								dense
							></v-textarea>
							<v-text-field
								type="email"
								color="info"
								v-model="editedItem.email"
								label="E-mail *"
								:rules="[rules.required, rules.email]"
								required
								outlined
								dense
							></v-text-field>
							<v-text-field
								color="info"
								v-model="editedItem.mobile_phone"
								label="Мобильный телефон"
								:rules="[rules.mobile_phone]"
								placeholder="89999999999"
								required
								outlined
								dense
							></v-text-field>
						</v-card-text>

						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="close"> Отмена </v-btn>
							<v-btn
								color="primary"
								@click="save"
								:disabled="!validationCheck()"
							>
								Сохранить
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogShow" max-width="500px">
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ editedItem.title }}</span>
						</v-card-title>
						<v-card-subtitle>
							<span class="subtitle-2">{{ editedItem.mobile_phone }}, {{ editedItem.email }}</span>
						</v-card-subtitle>
						<v-card-text>
							<p>{{ editedItem.message }}</p>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="closeShow">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<v-card-title>
							<span class="text-h5">Удаление</span>
						</v-card-title>
						<v-card-text>
							Вы действительно хотите удалить эту обратную связь?
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="deleteItemConfirm">
								Да
							</v-btn>
							<v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item.actions="{ item }" style="min-width: 200px">
			<v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
				mdi-eye
			</v-icon>
			<v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
				mdi-pencil
			</v-icon>
			<v-icon dense color="secondary" @click="deleteItem(item)">
				mdi-delete
			</v-icon>
		</template>
		<template v-slot:no-data>
			<v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
		</template>
	</v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Заголовок", value: "title" },
			{ text: "Сообщение", value: "message" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле",
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Некорректный e-mail'
			},
			mobile_phone: value => {
				const pattern = /^\d{11}$/;
				return pattern.test(value) || "Некорректный номер";
			}
		},
		// FEEDBACKS: [],
		editedIndex: -1,
		editedItem: {
			category_id: null,
			email: null,
			message: null,
			mobile_phone: null,
			title: null,
		},
		defaultItem: {
			category_id: null,
			email: null,
			message: null,
			mobile_phone: null,
			title: null,
		},
		categories: [],
		paginationSetter: null
	}),

	computed: {
		...mapGetters(["FEEDBACKS", "DIRECTORIES", "FEEDBACKS_PAGINATION"]),

		pagination: {
			get() {
				return this.FEEDBACKS_PAGINATION;
			},
			set() {
				this.paginationSetter = this.FEEDBACKS_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1
				? "Создать обратную связь"
				: "Обновить обратную связь";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_FEEDBACKS_FROM_API",
			"CREATE_FEEDBACK_TO_API",
			"UPDATE_FEEDBACK_FROM_API",
			"DELETE_FEEDBACK_FROM_API",
			// v-select
			"GET_DIRECTORIES_FROM_API"
		]),

		initialize(props) {
			this.loading = true;

			this.GET_FEEDBACKS_FROM_API({
				pagination: props // pagination
			}).then(() => (this.loading = false));
			this.GET_DIRECTORIES_FROM_API().then(() => {
				this.categories = this.DIRECTORIES.feedbacks.categories;
			});
		},

		showItem(item) {
			this.editedIndex = this.FEEDBACKS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.FEEDBACKS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.FEEDBACKS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_FEEDBACK_FROM_API(this.editedItem);
			this.FEEDBACKS.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		toggleActiveSection(id) {
			this.loading = true;
			this.paginationSetter.itemsPerPage = this.paginationSetter.rowsPerPage;

			this.GET_FEEDBACKS_FROM_API({
				section_id: id,
				pagination: this.pagination
			}).then(() => (this.loading = false));
		},

		save() {
			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };

				for (let key in fd)
					if (fd[key] === '' || fd[key] === null)
						delete fd[key];

				this.UPDATE_FEEDBACK_FROM_API(fd);
				Object.assign(this.FEEDBACKS[this.editedIndex], this.editedItem);
			} else {
				let fd = { ...this.editedItem };

				for (let key in fd)
					if (fd[key] === '' || fd[key] === null)
						delete fd[key];

				this.CREATE_FEEDBACK_TO_API(fd);
			}
			this.close();
		},

		validationCheck() {
			if (
				this.rules.required(this.editedItem.category_id) === true &&
				this.rules.required(this.editedItem.title) === true &&
				this.rules.required(this.editedItem.message) === true &&
				this.rules.required(this.editedItem.email) === true
			) {
				return true;
			}
		}
	}
};
</script>
