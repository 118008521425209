import axios from "axios";

export default {
	state: {
		users: [],
		userStatus: {}, // response obj
		userPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_USERS_TO_STATE: (state, users) => {
			state.users = users;
		},
		SET_USER_TO_STATE: (state, user) => {
			state.users.push(user);
		},
		UPDATE_USER_FROM_STATE: (state, user) => {
			const index = state.users.findIndex(r => r.id === user.id);
			if (index !== -1) state.users.splice(index, 1, user);
		},
		DELETE_USER_FROM_STATE: (state, user) => {
			state.users = state.users.filter(r => user.id !== r.id);
		},
		// set response
		SET_USER_RESPONSE_TO_STATE: (state, response) => {
			state.userStatus = response;
		},
		// set metadata for pagination
		SET_USERS_PAGINATION_TO_STATE: (state, response) => {
			state.userPagination.page = response.page;
			state.userPagination.rowsPerPage = response.per_page;
			state.userPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of users
		GET_USERS_FROM_API({ commit }, params) {
			return axios(`/users?include_metadata=enable&page=${ params.pagination.page }&per_page=${ params.pagination.itemsPerPage }`, {
				method: "GET"
			})
				.then(users => {
					commit("SET_USERS_TO_STATE", users.data.items);
					commit(
						"SET_USERS_PAGINATION_TO_STATE",
						users.data._metadata.pagination
					);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new user
		CREATE_USER_TO_API({ commit }, user) {
			return axios("/users", {
				method: "POST",
				data: user
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_USER_TO_STATE", response.data);

						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Пользователь успешно добавлен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update user
		UPDATE_USER_FROM_API({ commit }, user) {
			return axios("/users/" + user.id, {
				method: "PUT",
				data: user
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_USER_FROM_STATE", response.data);

						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Пользователь успешно обновлен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete user
		DELETE_USER_FROM_API({ commit }, user) {
			return axios("/users/" + user.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_USER_FROM_STATE", user);

						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Пользователь успешно удален",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// block user
		BLOCK_USER({ commit }, user) {
			return axios("/users/" + user.id + "/block", {
				method: "PUT"
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_USER_FROM_STATE", response.data);

						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Пользователь успешно заблокирован",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_USER_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// unblock user
		UNBLOCK_USER({ commit }, user) {
			return axios("/users/" + user.id + "/unblock", {
				method: "PUT"
			})
			.then(response => {
				if (response.status === 200)
					commit("UPDATE_USER_FROM_STATE", response.data);

				commit("SET_USER_RESPONSE_TO_STATE", {
					text: "Пользователь успешно разблокирован",
					type: "success",
					show: true
				});
			})
			.catch(error => {
				console.log(error.response.data); // debug
				if (error.response.status === 401) {
					commit("SET_USER_RESPONSE_TO_STATE", {
						text: "Пользователь не авторизован",
						type: "error",
						show: true
					});
				} else {
					commit("SET_USER_RESPONSE_TO_STATE", {
						text: "Непредвиденная ошибка",
						type: "error",
						show: true
					});
				}
			});
		}
	},
	getters: {
		USERS(state) {
			return state.users;
		},
		USER_STATUS(state) {
			return state.userStatus;
		},
		USERS_PAGINATION(state) {
			return state.userPagination;
		}
	}
};
