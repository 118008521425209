import axios from "axios";

export default {
	state: {
		exercises: [],
		exerciseStatus: {} // response obj
	},
	mutations: {
		SET_EXERCISES_TO_STATE: (state, exercises) => {
			state.exercises = exercises;
		},
		SET_EXERCISE_TO_STATE: (state, exercise) => {
			state.exercises.push(exercise);
		},
		DELETE_EXERCISE_FROM_STATE: (state, exercise) => {
			state.exercises = state.exercises.filter(r => exercise.id !== r.id);
		},
		// set response
		SET_EXERCISE_RESPONSE_TO_STATE: (state, response) => {
			state.exerciseStatus = response;
		}
	},
	actions: {
		// pin new exercise
		PIN_EXERCISE_TO_API({ commit }, params) {
			return axios(`programs/${ params.parent_id }/lessons/${ params.lesson_id }/exercises/${ params.exercise_id }`, {
				method: "POST",
				data: params.obj
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_EXERCISE_TO_STATE", response.data);

						commit("SET_EXERCISE_RESPONSE_TO_STATE", {
							text: "Упражнение успешно прикреплено",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data.description);
					if (error.response.status === 400) {
						commit("SET_EXERCISE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_EXERCISE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_EXERCISE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete exercise
		UNPIN_EXERCISE_FROM_API({ commit }, params) {
			console.log(params)
			return axios(`programs/${ params.parent_id }/lessons/${ params.lesson_id }/exercises/${ params.exercise_id }`, {
				method: "DELETE",
				data: params.obj
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_EXERCISE_FROM_STATE", response.data);

						commit("SET_EXERCISE_RESPONSE_TO_STATE", {
							text: "Упражнение успешно откреплено",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data.description)
					if (error.response.status === 401) {
						commit("SET_EXERCISE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_EXERCISE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		EXERCISES(state) {
			return state.exercises;
		},
		EXERCISE_STATUS(state) {
			return state.exerciseStatus;
		}
	}
};
