<template>
	<div>
		<v-footer app color="primary" dark class="footer"> </v-footer>
	</div>
</template>

<script>
export default {
	name: "MainHeader",

	data() {
		return {};
	}
};
</script>

<style scoped>
.footer {
	height: 36px;
	background-image: url(../assets/header-bg.svg);
}
</style>
