import Vue from "vue";
import VueRouter from "vue-router";

// views
import Home from "../views/Home.vue";
import Roles from "../views/Roles.vue";
import Users from "../views/Users.vue";
import Categories from "../views/Categories.vue";
import Subcategories from "../views/Subcategories.vue";
import Profiles from "../views/Profiles.vue";
import Files from "../views/Files.vue";
import Feedbacks from "../views/Feedbacks";

import Root from "../views/rootParent.vue";

import Articles from "../views/articles/Articles.vue";
import Theories from "../views/articles/Theories.vue";
import Practices from "../views/articles/Practices.vue";

import Programs from "../views/programs/Programs.vue";
import Lessons from "../views/programs/Lessons.vue";

import Polls from "../views/polls/Polls.vue";
import Questions from "../views/polls/Questions.vue";
import Answers from "../views/polls/Answers.vue";

import Page404 from "../views/Page404.vue";

// auth
import Signin from "../views/auth/Signin.vue";
import Signup from "../views/auth/Signup.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		meta: { auth: false },
		component: Home
	},
	{
		path: "/roles",
		name: "roles",
		meta: { auth: true },
		component: Roles
	},
	{
		path: "/users",
		name: "users",
		meta: { auth: true },
		component: Users
	},
	{
		path: "/directories/categories",
		name: "categories",
		meta: { auth: true },
		component: Categories
	},
	{
		path: "/directories/subcategories",
		name: "subcategories",
		meta: { auth: true },
		component: Subcategories
	},
	{
		path: "/profiles",
		name: "profiles",
		meta: { auth: true },
		component: Profiles
	},
	{
		path: "/files",
		name: "files",
		meta: { auth: true },
		component: Files
	},
	{
		path: "/articles",
		name: "articles",
		meta: { auth: true },
		component: Articles
	},
	{
		path: "/theories",
		name: "theories",
		meta: { auth: true },
		component: Theories
	},
	{
		path: "/practices",
		name: "practices",
		meta: { auth: true },
		component: Practices
	},
	{
		path: "/programs",
		meta: { auth: true },
		component: Root,
		children: [
			{
				path: '',
				name: 'programs',
				component: Programs
			},
			{
				path: ":id/lessons",
				name: "lessons",
				component: Lessons
			}
		]
	},
	{
		path: "/polls",
		meta: { auth: true },
		component: Root,
		children: [
			{
				path: '',
				name: 'polls',
				component: Polls
			},
			{
				path: ":id/questions",
				name: "questions",
				component: Questions,
				children: [
					{
						path: ":id/answers",
						name: "answers",
						component: Answers
					}
				]
			}
		]
	},
	{
		path: "/feedbacks",
		name: "feedbacks",
		meta: { auth: true },
		component: Feedbacks
	},
	{
		path: "/auth/signin",
		name: "signin",
		component: Signin
	},
	{
		path: "/auth/signup",
		name: "signup",
		component: Signup
	},
	{
		path: "/:catchAll(.*)",
		name: "page404",
		component: Page404
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
