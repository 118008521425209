<template>
	<div>
		<v-app-bar app color="primary" dark large src="../assets/header-bg.svg">
			<template v-slot:img="{ props }">
				<v-img v-bind="props"></v-img>
			</template>
			<div class="d-flex align-center logotype">
				<v-img
					alt="red-machine logotype"
					class="shrink"
					contain
					src="../assets/logo.png"
					width="70"
				/>
			</div>
			<v-tabs class="v-tabs" show-arrows background-color="transparent">
				<v-tab
					:to="link.route"
					v-for="link in links"
					:key="link.name"
					:disabled="link.disabled"
					active-class="link-active"
				>
					{{ link.name }}
				</v-tab>
			</v-tabs>

			<div v-show="isAuth">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn class="ma-0" v-on="on" icon @click="goSignin">
							<v-icon dark> mdi-logout </v-icon>
						</v-btn>
					</template>
					<span>Выйти</span>
				</v-tooltip>
			</div>
			<div v-show="!isAuth">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<router-link :to="{ name: 'signin' }">
							<v-btn class="ma-0" v-on="on" icon>
								<v-icon dark> mdi-account </v-icon>
							</v-btn>
						</router-link>
					</template>
					<span>Войти</span>
				</v-tooltip>
			</div>
		</v-app-bar>
	</div>
</template>

<script>
export default {
	name: "MainHeader",

	methods: {
		goSignin() {
			this.$store.dispatch("LOGOUT").then(() => {
				localStorage.removeItem("access_token");
				localStorage.removeItem("refresh_token");

				this.$axios.defaults.headers.common["Authorization"] = null;

				this.$router.push({ name: "signin" });
			});
		}
	},

	computed: {
		isAuth() {
			return this.$store.getters.IS_AUTH;
		}
	},

	data() {
		return {
			logoutShow: true,
			links: [
				{
					name: "Главная",
					route: "/",
					disabled: false
				},
				{
					name: "Роли",
					route: "/roles",
					disabled: false
				},
				{
					name: "Пользователи",
					route: "/users",
					disabled: false
				},
				{
					name: "Справочник",
					route: "/directories/categories",
					disabled: false
				},
				// {
				// 	name: "Профили",
				// 	route: "/profiles",
				// 	disabled: false
				// },
				{
					name: "Файлы",
					route: "/files",
					disabled: false
				},
				{
					name: "Статьи",
					route: "/articles",
					disabled: false
				},
				{
					name: "Теория",
					route: "/theories",
					disabled: false
				},
				{
					name: "Практика",
					route: "/practices",
					disabled: false
				},
				{
					name: "Программы",
					route: "/programs",
					disabled: false
				},
				{
					name: "Опросы",
					route: "/polls",
					disabled: false
				},
				{
					name: "Обратная связь",
					route: "/feedbacks",
					disabled: false
				}
			]
		};
	}
};
</script>

<style scoped>
.logotype {
	width: 10%;
	min-width: 32px;
}

.v-tabs {
	width: 80%;
}

/* .v-toolbar__content {
  justify-content: space-between;
} */

.link-active {
	background-color: hsla(0, 0%, 100%, 0.2);
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
	color: #fff;
}

.v-tab--disabled {
	opacity: 0.6;
}

.exit-btn {
	width: 10%;
	min-width: 48px;
	display: flex;
	justify-content: flex-end;
}
</style>
