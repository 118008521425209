<template>
  <v-data-table
    :loading="loading"
    loading-text="Пожалуйста подождите..."
    :headers="headers"
    :items="CATEGORIES"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15, 30],
      itemsPerPageText: 'Строк на странице',
      itemsPerPageAllText: 'Все',
      pageText: '{0} - {1} из {2}',
    }"
    :options.sync="pagination"
    :server-items-length="pagination.totalItems"
    @update:options="initialize"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Категории</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-select
          :items="content_types"
          v-model="content_type_selected"
          item-value="id"
          item-text="name"
          label="Раздел"
          dense
          outlined
          hide-details
          class="sections-sort"
          @change="toggleActiveSection"
        ></v-select>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Создать категорию
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-select
                color="info"
                item-color="info"
                :items="content_types"
                v-model="editedItem.content_type"
                item-value="id"
                item-text="name"
                label="Раздел *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-select>

              <v-sheet>
                <span>Иконка</span>
                <div v-if="editedItem.icon">
                  <p>Сейчас используется</p>
                  <v-img :src="editedItem.icon.thumbnail" alt="" />
                </div>
                <v-slide-group
                  v-model="editedItem.icon_id"
                  class="py-4 pt-2"
                  center-active
                  show-arrows
                >
                  <v-slide-item
                    v-for="file in icons_ids"
                    :key="file.id"
                    :value="file.id"
                    v-slot="{ active, toggle }"
                  >
                    <v-card
                      :color="active ? 'info' : 'grey lighten-1'"
                      class="ma-1"
                      height="100"
                      width="100"
                      style="overflow: hidden"
                      @click="toggle"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-img height="80" :src="file.thumbnail"></v-img>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <v-text-field
                color="info"
                v-model="editedItem.name"
                label="Название *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-text-field>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="close"> Отмена </v-btn>
              <v-btn
                color="primary"
                @click="save"
                :disabled="!validationСheck()"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShow" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ editedItem.name }}</span>
            </v-card-title>
            <v-card-text>
              <v-img
                v-if="editedItem.icon"
                :src="editedItem.icon.thumbnail"
                alt=""
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeShow">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Удаление</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите удалить эту категорию?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="deleteItemConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
        mdi-eye
      </v-icon>
      <v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon dense color="secondary" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Название", value: "name" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле"
		},
		// CATEGORIES: [],
		content_type_selected: "",
		content_types: [
			{ id: "theories", name: "Теория" },
			{ id: "practices", name: "Практика" },
			{ id: "programs", name: "Программы" },
			{ id: "polls", name: "Опросы" },
			{ id: "feedbacks", name: "Обратная связь" }
		],
		editedIndex: -1,
		editedItem: {
			content_type: null,
			icon_id: null,
			name: null
		},
		defaultItem: {
			content_type: null,
			icon_id: null,
			name: null
		},
		paginationSetter: null,
		icons_ids: []
	}),

	computed: {
		...mapGetters(["CATEGORIES", "DIRECTORIES", "CATEGORIES_PAGINATION"]),

		pagination: {
			get() {
				return this.CATEGORIES_PAGINATION;
			},
			set() {
				this.paginationSetter = this.CATEGORIES_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1
				? "Создать категорию"
				: "Обновить категорию";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_CATEGORIES_FROM_API",
			"CREATE_CATEGORY_TO_API",
			"UPDATE_CATEGORY_FROM_API",
			"DELETE_CATEGORY_FROM_API",
			// icons
			"GET_DIRECTORIES_FROM_API"
		]),

		initialize(props) {
			this.loading = true;

			if (!this.content_type_selected)
				this.content_type_selected = this.content_types[0].id;

			this.GET_CATEGORIES_FROM_API({
				section_id: this.content_type_selected,
				pagination: props // pagination
			}).then(() => (this.loading = false));

			this.GET_DIRECTORIES_FROM_API().then(() => {
				this.icons_ids = [];
				for (let key in this.DIRECTORIES.files) {
					if (this.DIRECTORIES.files[key].mime_type !== "video/mp4")
						this.icons_ids.push(this.DIRECTORIES.files[key]);
				}
			});
		},

		showItem(item) {
			this.editedIndex = this.CATEGORIES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.CATEGORIES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.CATEGORIES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_CATEGORY_FROM_API(this.editedItem);
			this.CATEGORIES.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		toggleActiveSection(id) {
			this.loading = true;
			this.paginationSetter.itemsPerPage = this.paginationSetter.rowsPerPage;

			this.GET_CATEGORIES_FROM_API({
				section_id: id,
				pagination: this.pagination
			}).then(() => (this.loading = false));
		},

		save() {
			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };

				for (let key in fd)
					if (fd[key] === '' || fd[key] === null)
						delete fd[key];

				delete fd.icon;
				delete fd.subcategories;

				this.UPDATE_CATEGORY_FROM_API(fd);
				Object.assign(this.CATEGORIES[this.editedIndex], this.editedItem);
			} else {
				let fd = { ...this.editedItem };

				for (let key in fd)
					if (fd[key] === '' || fd[key] === null)
						delete fd[key];

				this.CREATE_CATEGORY_TO_API(fd);
			}
			this.close();
		},

		validationСheck() {
			if (
				this.rules.required(this.editedItem.content_type) == true &&
				this.rules.required(this.editedItem.name) == true
			) {
				return true;
			}
		}
	}
};
</script>

<style scoped>
.sections-sort {
  width: 0px;
}
</style>
