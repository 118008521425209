<template>
	<div>
		<v-container>
			<alert-bar :snackbar="alertInfo" />
			<v-breadcrumbs :items="computedBreadcrumbs" divider="/"></v-breadcrumbs>
			<data-table />
		</v-container>
	</div>
</template>

<script>
// data table
import DataTable from "@/components/data-tables/polls/Questions";
// alert bar for message
import AlertBar from "@/components/AlertBar";
import { mapActions } from "vuex";

export default {
	name: "Polls",
	data() {
		return {
			breadcrumbs: [
				{
					text: "Опросы",
					to: "/polls",
					disabled: false,
					exact: true
				},
				{
					text: "",
					to: "/polls",
					disabled: true
				}
			]
		};
	},
	methods: {
		...mapActions(["GET_POLL_FROM_API"])
	},
	computed: {
		alertInfo() {
			return this.$store.getters.ANSWER_STATUS;
		},

		computedBreadcrumbs() {
			let items = this.breadcrumbs;
			items[0].disabled = false;
			items[1].text = this.$store.getters.POLL.title;

			return items;
		}
	},
	components: {
		DataTable,
		AlertBar
	},
	mounted() {
		this.GET_POLL_FROM_API(this.$route.params.id);
	}
};
</script>
