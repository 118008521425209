<template>
  <v-app>
    <main-header />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <main-footer />
  </v-app>
</template>

<script>
// main components
import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";
import router from "./router";

export default {
	name: "App",

	data() {
		return {};
	},

	methods: {
		baseAuth() {
			this.$store.dispatch("BASE_AUTH")
			.then(() => {
				router.beforeEach((to, from, next) => {

					const currentUser = this.$store.getters.IS_AUTH;
					const requireAuth = to.matched.some(record => record.meta.auth);

					requireAuth && !currentUser
						? next('/auth/signin')
						: next();
				});
			});
		}
	},

	mounted() {
		const token = localStorage.getItem('access_token');

		token
			? this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
			: this.baseAuth();
	},

	components: {
		MainHeader,
		MainFooter
	}
};
</script>
