import axios from "axios";

export default {
	state: {
		profiles: [],
		profileStatus: {} // response obj
	},
	mutations: {
		SET_PROFILES_TO_STATE: (state, profiles) => {
			state.profiles = profiles;
		},
		SET_PROFILE_TO_STATE: (state, profile) => {
			state.profiles.push(profile);
		},
		UPDATE_PROFILE_FROM_STATE: (state, profile) => {
			const index = state.profiles.findIndex(r => r.id === profile.id);
			if (index !== -1) state.profiles.splice(index, 1, profile);
		},
		DELETE_PROFILE_FROM_STATE: (state, profile) => {
			state.profiles = state.profiles.filter(r => profile.id !== r.id);
		},
		// set response
		SET_PROFILE_RESPONSE_TO_STATE: (state, response) => {
			state.profileStatus = response;
		}
	},
	actions: {
		// return a list of profiles
		GET_PROFILES_FROM_API({ commit }) {
			return axios("/profiles", {
				method: "GET"
			})
				.then(profiles => {
					commit("SET_PROFILES_TO_STATE", profiles.data.items);
				})
				.catch(error => {
					console.log(error);
				});
		},
		// create new profile
		CREATE_PROFILE_TO_API({ commit }, profile) {
			return axios("/profiles", {
				method: "POST",
				data: profile
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_PROFILE_TO_STATE", response.data);

						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Профиль успешно добавлен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					if (error.response.status === 400) {
						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update profile
		UPDATE_PROFILE_FROM_API({ commit }, profile) {
			return axios("/profiles/" + profile.id, {
				method: "PUT",
				data: profile
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_PROFILE_FROM_STATE", response.data);

						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Профиль успешно обновлен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					if (error.response.status === 400) {
						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete profile
		DELETE_PROFILE_FROM_API({ commit }, profile) {
			return axios("/profiles/" + profile.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_PROFILE_FROM_STATE", response.data);

						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Профиль успешно удалён",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					if (error.response.status === 401) {
						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PROFILE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		PROFILES(state) {
			return state.profiles;
		},
		PROFILE_STATUS(state) {
			return state.profileStatus;
		}
	}
};
