import axios from "axios";

export default {
	state: {
		article: {},
		articles: [],
		articleStatus: {}, // response obj
		articlesPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_ARTICLES_TO_STATE: (state, articles) => {
			state.articles = articles;
		},
		SET_ARTICLE_TO_STATE: (state, article) => {
			state.articles.push(article);
		},
		SET_ARTICLE: (state, article) => {
			state.article = article
		},
		UPDATE_ARTICLE_FROM_STATE: (state, article) => {
			const index = state.articles.findIndex(r => r.id === article.id);
			if (index !== -1) state.articles.splice(index, 1, article);
		},
		DELETE_ARTICLE_FROM_STATE: (state, article) => {
			state.articles = state.articles.filter(r => article.id !== r.id);
		},
		// set response
		SET_ARTICLE_RESPONSE_TO_STATE: (state, response) => {
			state.articleStatus = response;
		},
		// set metadata for pagination
		SET_ARTICLES_PAGINATION_TO_STATE: (state, response) => {
			state.articlesPagination.page = response.page;
			state.articlesPagination.rowsPerPage = response.per_page;
			state.articlesPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of articles
		GET_ARTICLES_FROM_API({ commit }, params) {
			return axios(`articles?include_metadata=enable&page=${ params.page }&per_page=${ params.rowsPerPage }&is_published=${ params.is_published }`, {
				method: "GET"
			})
				.then(articles => {
					commit("SET_ARTICLES_TO_STATE", articles.data.items);
					commit(
						"SET_ARTICLES_PAGINATION_TO_STATE",
						articles.data._metadata.pagination
					);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// return theory by id
		GET_ARTICLE_FROM_API({ commit }, id) {
			return axios(`articles/${ id }`, {
				method: "GET"
			})
				.then(items => {
					commit("SET_ARTICLE", items.data);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new article
		CREATE_ARTICLE_TO_API({ commit }, article) {
			return axios("/articles", {
				method: "POST",
				data: article
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_ARTICLE_TO_STATE", response.data);

						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Статья успешно добавлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 400) {
						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.data.code === 401) {
						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update article
		UPDATE_ARTICLE_FROM_API({ commit }, article) {
			return axios("/articles/" + article.id, {
				method: "PUT",
				data: article
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_ARTICLE_FROM_STATE", response.data);

						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Статья успешно обновлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 400) {
						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.data.code === 401) {
						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete article
		DELETE_ARTICLE_FROM_API({ commit }, article) {
			return axios("/articles/" + article.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_ARTICLE_FROM_STATE", article);

						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Статья успешно удалена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_ARTICLE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		ARTICLE(state) {
			return state.article || {}
		},
		ARTICLES(state) {
			return state.articles;
		},
		ARTICLE_STATUS(state) {
			return state.articleStatus;
		},
		ARTICLES_PAGINATION(state) {
			return state.articlesPagination;
		}
	}
};
