import Vue from "vue";
import Vuex from "vuex";

import controls from "./modules/controls"
import roles from "./modules/roles"
import users from "./modules/users"
import directories from "./modules/directories"
import categories from "./modules/categories"
import subcategories from "./modules/subcategories"
import profiles from "./modules/profiles"
import files from "./modules/files"
// articles
import articles from "./modules/articles/articles"
import theories from "./modules/articles/theories"
import practices from "./modules/articles/practices"
// programs
import programs from "./modules/programs/programs"
import lessons from "./modules/programs/lessons"
import exercises from "./modules/programs/exercises"
//polls
import polls from "./modules/polls/polls"
import questions from "./modules/polls/questions"
import feedbacks from "./modules/feedbacks";

import auth from "./modules/auth"
import search from "./modules/search"

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		controls,
		roles,
		users,
		directories,
		categories,
		subcategories,
		profiles,
		files,
		articles,
		theories,
		practices,
		programs, lessons, exercises,
		polls, questions,
		feedbacks,
		auth,
		search
	}
});
