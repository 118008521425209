<template>
  <v-data-table
    :loading="loading"
    loading-text="Пожалуйста подождите..."
    :headers="headers"
    :items="USERS"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15, 30],
      itemsPerPageText: 'Строк на странице',
      itemsPerPageAllText: 'Все',
      pageText: '{0} - {1} из {2}',
    }"
    :options.sync="pagination"
    :server-items-length="pagination.totalItems"
    @update:options="initialize"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Пользователи</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Создать пользователя
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-text-field
                type="email"
                color="info"
                v-model="editedItem.email"
                label="E-mail *"
                :rules="[rules.email, rules.required]"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                color="info"
                type="tel"
                v-model="editedItem.mobile_phone"
                label="Мобильный телефон *"
                :rules="[rules.phone, rules.required]"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-if="editedIndex === -1"
                color="info"
                v-model="editedItem.password"
                label="Пароль *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-text-field>
              <v-select
                :items="ROLES"
                color="info"
                v-model="editedItem.role"
                item-value="name"
                item-text="name"
                label="Роль *"
                :rules="[rules.required]"
                required
                dense
                outlined
              ></v-select>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="close"> Отмена </v-btn>
              <v-btn
                color="primary"
                @click="save"
                :disabled="!validationСheck()"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShow" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ editedItem.email }}</span>
            </v-card-title>
            <v-card-text>
              Телефон: {{ editedItem.mobile_phone }} <br />
              Роль:
              {{ editedItem.role }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeShow">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Удаление</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите удалить этого пользователя?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="deleteItemConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogReverse" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Блокировка</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите {{ reverseStatusText }} этого
              пользователя?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="reverseItemConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeReverse"> Отмена </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.is_blocked="{ item }">
      <div>
        {{ getPhrase(item.is_blocked) }}
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
        mdi-eye
      </v-icon>
      <v-icon dense class="mr-2" color="secondary" @click="reverseItem(item)">
        {{ getIcon(item.is_blocked) }}
      </v-icon>
      <v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon dense color="secondary" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		dialogReverse: false,
		headers: [
			{ text: "E-mail", value: "email" },
			{ text: "Мобильный телефон", value: "mobile_phone" },
			{ text: "Роль", value: "role" },
			{ text: "Статус", value: "is_blocked" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле",
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return pattern.test(value) || "Некорректный e-mail";
			},
			phone: value => {
				const pattern = /^\d{11}$/;
				return pattern.test(value) || "Некорректный номер телефона";
			}
		},
		editedIndex: -1,
		editedItem: {
			email: null,
			mobile_phone: null,
			password: null,
			role: null
		},
		defaultItem: {
			email: null,
			mobile_phone: null,
			password: null,
			role: null
		},
		reverseStatusText: null,
		paginationSetter: null
	}),

	computed: {
		...mapGetters(["USERS", "ROLES", "USERS_PAGINATION"]),

		pagination: {
			get() {
				return this.USERS_PAGINATION;
			},
			set() {
				this.paginationSetter = this.USERS_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1
				? "Создать пользователя"
				: "Обновить пользователя";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_USERS_FROM_API",
			"CREATE_USER_TO_API",
			"UPDATE_USER_FROM_API",
			"DELETE_USER_FROM_API",
			"BLOCK_USER",
			"UNBLOCK_USER",
			// v-select
			"GET_ROLES_FROM_API"
		]),

		initialize(props) {
			this.loading = true;
			this.GET_USERS_FROM_API({
				pagination: props // pagination
			}).then(() => (this.loading = false));
			this.GET_ROLES_FROM_API(null);
		},

		showItem(item) {
			this.editedIndex = this.USERS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.USERS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.USERS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_USER_FROM_API(this.editedItem);
			this.USERS.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		reverseItem(item) {
			item.is_blocked
				? (this.reverseStatusText = "разблокировать")
				: (this.reverseStatusText = "заблокировать");

			this.editedIndex = this.USERS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogReverse = true;
		},

		reverseItemConfirm() {
			this.editedItem.is_blocked
				? this.UNBLOCK_USER(this.editedItem)
				: this.BLOCK_USER(this.editedItem);

			this.editedItem.is_blocked = !this.editedItem.is_blocked;
			let fd = { ...this.editedItem };
			this.UPDATE_USER_FROM_API(fd);

			this.closeReverse();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeReverse() {
			this.dialogReverse = false;
		},

		save() {
			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };

				this.UPDATE_USER_FROM_API(fd);
				Object.assign(this.USERS[this.editedIndex], this.editedItem);
			} else {
				let fd = { ...this.editedItem };

				this.CREATE_USER_TO_API(fd);
			}
			this.close();
		},

		validationСheck() {
			if (
				this.rules.email(this.editedItem.email) == true &&
				this.rules.phone(this.editedItem.mobile_phone) == true &&
				this.rules.required(this.editedItem.role) == true
			) {
				return true;
			}
		},

		getColor(is_blocked) {
			return is_blocked ? "red" : "green";
		},

		getPhrase(is_blocked) {
			return is_blocked ? "Заблокирован" : "Активный";
		},

		getIcon(is_blocked) {
			return is_blocked ? "mdi-lock-open" : "mdi-lock";
		}
	}
};
</script>
