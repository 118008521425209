<template>
	<v-data-table
		:loading="loading"
		loading-text="Пожалуйста подождите..."
		:headers="headers"
		:items="QUESTIONS"
		:footer-props="{
			itemsPerPageOptions: [5, 10, 15, 30],
			itemsPerPageText: 'Строк на странице',
			itemsPerPageAllText: 'Все',
			pageText: '{0} - {1} из {2}'
		}"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Вопросы</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" scrollable max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
							Создать вопрос
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>
						<v-divider></v-divider>

						<v-card-text class="pt-5">
							<v-text-field
								color="info"
								v-model="editedItem.question"
								label="Вопрос *"
								:rules="[rules.required]"
								required
								outlined
								dense
							></v-text-field>
							<v-checkbox
								color="info"
								v-model="editedItem.multiple_answer"
								label="Несколько вариантов"
								hide-details
							></v-checkbox>
						</v-card-text>

						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="close"> Отмена </v-btn>
							<v-btn
								color="primary"
								@click="save"
								:disabled="!validationСheck()"
							>
								Сохранить
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogShow" max-width="500px">
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ editedItem.question }}</span>
						</v-card-title>
						<v-card-text>
							<v-list-item
								v-for="answer in editedItem.answers"
								:key="answer.id"
							>
								<v-list-item-content>
									<v-list-item-title>{{ answer.answer }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="closeShow">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<v-card-title>
							<span class="text-h5">Удаление</span>
						</v-card-title>
						<v-card-text>
							Вы действительно хотите удалить этот вопрос?
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="deleteItemConfirm">
								Да
							</v-btn>
							<v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item.actions="{ item }">
			<router-link
				:to="`/polls/${$route.params.id}/questions/${item.id}/answers`"
				class="mr-2 i-link"
			>
				<v-btn x-small color="info">Ответы</v-btn>
			</router-link>
			<v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
				mdi-eye
			</v-icon>
			<v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
				mdi-pencil
			</v-icon>
			<v-icon dense color="secondary" @click="deleteItem(item)">
				mdi-delete
			</v-icon>
		</template>
		<template v-slot:no-data>
			<v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
		</template>
	</v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Вопрос", value: "question" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле"
		},
		//QUESTIONS: [],
		editedIndex: -1,
		editedItem: {
			question: null,
			poll_id: null,
			multiple_answer: false
		},
		defaultItem: {
			question: null,
			poll_id: null,
			multiple_answer: false
		},
		paginationSetter: {}
		// search: null
	}),

	computed: {
		...mapGetters(["QUESTIONS", "DIRECTORIES", "QUESTIONS_PAGINATION"]),

		pagination: {
			get() {
				return this.QUESTIONS_PAGINATION;
			},
			set() {
				this.paginationSetter = this.QUESTIONS_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1 ? "Создать вопрос" : "Обновить вопрос";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	created() {
		this.initialize();
	},

	methods: {
		...mapActions([
			"GET_QUESTIONS_FROM_API",
			"CREATE_QUESTION_TO_API",
			"UPDATE_QUESTION_FROM_API",
			"DELETE_QUESTION_FROM_API",
			// v-select
			"GET_DIRECTORIES_FROM_API"
		]),

		initialize() {
			this.loading = true;

			this.editedItem.poll_id = this.$route.params.id;

			// get questions list
			this.GET_QUESTIONS_FROM_API({
				poll_id: this.$route.params.id
			}).then(() => (this.loading = false));
		},

		showItem(item) {
			this.editedIndex = this.QUESTIONS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.QUESTIONS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.QUESTIONS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_QUESTION_FROM_API(this.editedItem);
			this.QUESTIONS.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			let fd = { ...this.editedItem };
			fd.poll_id = this.$route.params.id;

			if (this.editedIndex > -1) {
				this.UPDATE_QUESTION_FROM_API(fd);
				Object.assign(this.QUESTIONS[this.editedIndex], this.editedItem);
			} else {
				this.CREATE_QUESTION_TO_API(fd);
			}
			this.close();
		},

		validationСheck() {
			if (this.rules.required(this.editedItem.question) == true) {
				return true;
			}
		}
	}
};
</script>

<style scoped>
.i-link {
	text-decoration: none;
}
</style>
