import axios from "axios";

export default {
	state: {
		question: {},
		questions: [],
		questionStatus: {} // response obj
	},
	mutations: {
		SET_QUESTIONS_TO_STATE: (state, items) => {
			state.questions = items;
		},
		SET_QUESTION_TO_STATE: (state, item) => {
			state.questions.push(item);
		},
		SET_QUESTION: (state, item) => {
			state.question = item
		},
		UPDATE_QUESTION_FROM_STATE: (state, item) => {
			const index = state.questions.findIndex(r => r.id === item.id);
			if (index !== -1) state.questions.splice(index, 1, item);
		},
		DELETE_QUESTION_FROM_STATE: (state, item) => {
			state.questions = state.questions.filter(r => item.id !== r.id);
		},
		// set response
		SET_QUESTION_RESPONSE_TO_STATE: (state, response) => {
			state.questionStatus = response;
		}
	},
	actions: {
		// return a list of questions
		GET_QUESTIONS_FROM_API({ commit }, params) {

			return axios(`/polls/${ params.poll_id }`,
				{
					method: "GET"
				}
			)
			.then(questions => {
				commit("SET_QUESTIONS_TO_STATE", questions.data.questions); // questions
			})
			.catch(error => {
				console.log(error.response.data); // debug
			});
		},
		// return question by id
		GET_QUESTION_FROM_API({ commit }, params) {
			return axios(`/polls/${ params.poll_id }/questions/${ params.question_id }`, {
				method: "GET"
			})
				.then(questions => {
					commit("SET_QUESTION", questions.data);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new question
		CREATE_QUESTION_TO_API({ commit }, question) {
			return axios(`/polls/${ question.poll_id }/questions`, {
				method: "POST",
				data: question
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_QUESTION_TO_STATE", response.data);

						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Вопрос успешно добавлен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update question
		UPDATE_QUESTION_FROM_API({ commit }, question) {
			return axios(`/polls/${ question.poll_id }/questions/${ question.id }`, {
				method: "PUT",
				data: question
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_QUESTION_FROM_STATE", response.data);

						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Вопрос успешно обновлен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete question
		DELETE_QUESTION_FROM_API({ commit }, question) {
			return axios(`/polls/${ question.poll_id }/questions/${ question.id }`, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_QUESTION_FROM_STATE", question);

						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Вопрос успешно удален",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_QUESTION_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		QUESTION(state) {
			return state.question;
		},
		QUESTIONS(state) {
			return state.questions;
		},
		QUESTION_STATUS(state) {
			return state.questionStatus;
		}
	}
};
