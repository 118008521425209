import axios from "axios";

export default {
  state: {
		directories: []
	},
	mutations: {
		SET_DIRECTORIES_TO_STATE: (state, directories) => {
			state.directories = directories
		},
	},
	actions: {
		// return a list of directories
		GET_DIRECTORIES_FROM_API({commit}) {
			return axios('/directories', {
				method: 'GET'
			})
			.then(directories => {
				commit('SET_DIRECTORIES_TO_STATE', directories.data)
			})
			.catch(error => {
				console.log(error)
			})
		},
	},
	getters: {
		DIRECTORIES(state) {
			return state.directories;
		}
	}
}
