<template>
  <v-data-table
    :loading="loading"
    loading-text="Пожалуйста подождите..."
    :headers="headers"
    :items="LESSONS"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15, 30],
      itemsPerPageText: 'Строк на странице',
      itemsPerPageAllText: 'Все',
      pageText: '{0} - {1} из {2}',
    }"
    :options.sync="pagination"
    :server-items-length="pagination.totalItems"
    @update:options="initialize"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Занятия</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-select
          v-if="DIRECTORIES.programs"
          :items="DIRECTORIES.programs.categories"
          v-model="editedItem.category_id"
          item-value="id"
          item-text="name"
          label="Фаза *"
          dense
          outlined
          class="categories-sort mt-5 mr-2"
          @change="toggleActiveCategory"
        ></v-select>
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Создать занятие
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-slider
                class="mt-5"
                v-model="editedItem.number"
                label="Номер"
                :rules="[rules.required]"
                required
                thumb-color="info"
                thumb-label="always"
                min="1"
                max="10"
              ></v-slider>
              <v-slider
                class="mt-5"
                v-model="editedItem.period"
                label="Период"
                :rules="[rules.required]"
                required
                thumb-color="info"
                thumb-label="always"
                min="1"
                max="10"
              ></v-slider>
              <v-checkbox
                color="info"
                v-model="editedItem.is_exam"
                label="Экзамен"
                hide-details
              ></v-checkbox>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="close"> Отмена </v-btn>
              <v-btn
                color="primary"
                @click="save"
                :disabled="!validationСheck()"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogExercises" scrollable max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Занятие №{{ editedItem.number }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-autocomplete
                :items="practicesList"
                v-model="exerciseItem.exercise_id"
                item-value="id"
                item-text="title"
                label="Упражнение *"
                dense
                outlined
                :loading="loadingSelectPractices"
                :search-input.sync="loadingPractices"
                prepend-icon="mdi-magnify"
                hide-no-data
              ></v-autocomplete>
              <v-select
                :items="parts"
                v-model="exerciseItem.lesson_part"
                item-value="id"
                item-text="name"
                label="Часть урока *"
                dense
                outlined
              ></v-select>
              <v-slider
                class="mt-5"
                v-model="exerciseItem.order"
                label="Порядок"
                thumb-color="primary"
                thumb-label="always"
                min="1"
                max="10"
              ></v-slider>

              <v-list
                v-if="
                  LESSON.exercises &&
                  LESSON.exercises.preparatory &&
                  LESSON.exercises.preparatory.length > 0
                "
              >
                <v-list-group v-model="exercises.preparatory">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Подготовительная</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <transition-group>
                    <v-list-item
                      v-for="child in LESSON.exercises.preparatory"
                      :key="child.title"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="child.content"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          icon
                          @click="unpinExercise(child, 'preparatory')"
                        >
                          <v-icon color="secondary">mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition-group>
                </v-list-group>
              </v-list>

              <v-list
                v-if="
                  LESSON.exercises &&
                  LESSON.exercises.main &&
                  LESSON.exercises.main.length > 0
                "
              >
                <v-list-group v-model="exercises.main">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Основная</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <transition-group>
                    <v-list-item
                      v-for="child in LESSON.exercises.main"
                      :key="child.title"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="child.content"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="unpinExercise(child, 'main')">
                          <v-icon color="secondary">mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition-group>
                </v-list-group>
              </v-list>

              <v-list
                v-if="
                  LESSON.exercises &&
                  LESSON.exercises.final &&
                  LESSON.exercises.final.length > 0
                "
              >
                <v-list-group v-model="exercises.final">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Заключительная</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <transition-group>
                    <v-list-item
                      v-for="child in LESSON.exercises.final"
                      :key="child.title"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="child.content"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="unpinExercise(child, 'final')">
                          <v-icon color="secondary">mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition-group>
                </v-list-group>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="closeExercises">
                Отмена
              </v-btn>
              <v-btn color="primary" dark @click="pinExercises">
                Прикрепить упражнение
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShow" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ editedItem.title }}</span>
            </v-card-title>
            <v-card-text>
              {{ editedItem.abbreviation }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeShow">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Удаление</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите удалить это занятие?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="deleteItemConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogExDelete" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Удаление</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите открепить это упражнение?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="unpinExerciseConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeExDelete">
                Отмена
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.is_exam="{ item }">
      {{ setExamText(item.is_exam) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn x-small color="info mr-2" @click="exercisesItem(item)"
        >Упражнения</v-btn
      >
      <v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
        mdi-eye
      </v-icon>
      <v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon dense color="secondary" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		loadingSelectPractices: false,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		dialogExercises: false,
		dialogExDelete: false,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Номер", value: "number" },
			{ text: "Период", value: "period" },
			{ text: "", value: "is_exam" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле"
		},
		// LESSONS: [],
		parts: [
			{ id: "preparatory", name: "Подготовительная" },
			{ id: "main", name: "Основная" },
			{ id: "final", name: "Заключительная" }
		],
		editedIndex: -1,
		editedItem: {
			category_id: null,
			number: 0,
			period: 0,
			is_exam: false
		},
		defaultItem: {
			category_id: null,
			number: 0,
			period: 0,
			is_exam: false
		},
		exercises: {
			preparatory: false,
			main: false,
			final: false
		},
		exerciseItem: {
			exercise_id: null,
			lesson_part: null,
			order: 0
		},
		practicesList: [],
		lesson_part: null, //
		paginationSetter: {},
		hotItem: {},
		loadingPractices: ''
		// search: null
	}),

	computed: {
		...mapGetters([
			"LESSONS",
			"LESSON",
			"PROGRAM",
			"DIRECTORIES",
			"PRACTICES",
			"LESSONS_PAGINATION",
			"ITEMS"
		]),

		pagination: {
			get() {
				return this.LESSONS_PAGINATION;
			},
			set() {
				this.paginationSetter = this.LESSONS_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1 ? "Создать занятие" : "Обновить занятие";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},

		loadingPractices(queryText) {
			if (queryText === null) queryText = ''

			if (queryText.length > 1) {
				this.loadingSelectPractices = true;

				this.pagination.page = 1;
				this.pagination.meta = false;
				this.pagination.scope = 'practices';
				this.pagination.search = queryText;

				this.GET_ITEMS_BY_SEARCH(this.pagination).then(
					() => {
						this.practicesList = this.ITEMS
						this.loadingSelectPractices = false;
					}
				);
			}
		},

	},

	methods: {
		...mapActions([
			"GET_LESSONS_FROM_API",
			"GET_LESSON_FROM_API",
			"CREATE_LESSON_TO_API",
			"UPDATE_LESSON_FROM_API",
			"DELETE_LESSON_FROM_API",
			// program (parent)
			"GET_PROGRAM_FROM_API",
			// directories
			"GET_DIRECTORIES_FROM_API",
			// v-select
			"GET_PRACTICES_FROM_API",
			// exercises
			"PIN_EXERCISE_TO_API",
			"UNPIN_EXERCISE_FROM_API",
			// search
			"GET_ITEMS_BY_SEARCH"
		]),

		initialize(props) {
			this.GET_PRACTICES_FROM_API(null);
			this.practicesList = this.PRACTICES;

			this.GET_DIRECTORIES_FROM_API().then(() => {

				if (!this.editedItem.category_id)
					this.editedItem.category_id = this.DIRECTORIES.programs.categories[0].id;

				this.GET_PROGRAM_FROM_API(this.$route.params.id).then(() => {
					this.GET_LESSONS_FROM_API({
						parent_id: this.$route.params.id,
						category_id: this.editedItem.category_id,
						pagination: props // pagination
					}).then(() => (this.loading = false));
				});
			});
		},

		toggleActiveCategory(id) {
			this.loading = true;
			this.paginationSetter.itemsPerPage = this.paginationSetter.rowsPerPage;

			this.GET_LESSONS_FROM_API({
				parent_id: this.$route.params.id,
				category_id: id,
				pagination: this.pagination
			}).then(() => (this.loading = false));
		},

		setExamText(param) {
			return param ? "Экзамен" : "";
		},

		exercisesItem(item) {
			this.hotItem = item;

			this.loading = true;
			this.GET_LESSON_FROM_API({
				parent_id: this.$route.params.id,
				obj: this.hotItem
			}).then(() => {
				this.loading = false;
				//console.log(this.LESSON);
			});

			this.dialogExercises = true;
		},

		showItem(item) {
			this.editedIndex = this.LESSONS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.LESSONS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.LESSONS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_LESSON_FROM_API({
				parent_id: this.$route.params.id,
				obj: this.editedItem
			});
			this.LESSONS.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		unpinExercise(item, part) {
			// this.editedIndex = this.LESSON.exercises.preparatory.indexOf(item);
			this.exerciseItem.exercise_id = item.id;
			this.lesson_part = part;

			this.dialogExDelete = true;
		},

		unpinExerciseConfirm() {
			this.UNPIN_EXERCISE_FROM_API({
				parent_id: this.$route.params.id,
				lesson_id: this.LESSON.id,
				exercise_id: this.exerciseItem.exercise_id,
				obj: {
					lesson_part: this.lesson_part,
					order: this.exerciseItem.order
				}
			})
			.then(() => {
				this.GET_LESSON_FROM_API({
					parent_id: this.$route.params.id,
					obj: this.hotItem
				});
			});
			//this.LESSONS.splice(this.editedIndex, 1);
			//this.closeExercises();
			this.closeExDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				let ctgryId = this.editedItem.category_id;
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedItem.category_id = ctgryId;
				this.editedIndex = -1;
			});
		},

		closeExercises() {
			this.dialogExercises = false;
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				let ctgryId = this.editedItem.category_id;
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedItem.category_id = ctgryId;
				this.editedIndex = -1;
			});
		},

		closeExDelete() {
			this.dialogExDelete = false;
			// this.$nextTick(() => {
			// 	let ctgryId = this.editedItem.category_id;
			// 	this.editedItem = Object.assign({}, this.defaultItem);
			// 	this.editedItem.category_id = ctgryId;
			// 	this.editedIndex = -1;
			// });
		},

		pinExercises() {
			let fd = { ...this.exerciseItem };
			delete fd.exercise_id;

			this.PIN_EXERCISE_TO_API({
				parent_id: this.$route.params.id,
				lesson_id: this.LESSON.id,
				exercise_id: this.exerciseItem.exercise_id,
				obj: fd
			})
			.then(() => {
				this.GET_LESSON_FROM_API({
					parent_id: this.$route.params.id,
					obj: this.hotItem
				});
			});
		},

		save() {
			let fd = { ...this.editedItem };
			fd.program_id = this.$route.params.id;

			delete fd.exercises;
			delete fd.categories_percent; // delete because in swagger this field not need

			if (this.editedIndex > -1) {
				//delete fd.is_exam; // delete because in swagger this field not need

				this.UPDATE_LESSON_FROM_API({
					parent_id: this.$route.params.id,
					obj: fd
				});
				Object.assign(this.LESSONS[this.editedIndex], this.editedItem);
			} else {
				this.CREATE_LESSON_TO_API({
					parent_id: this.$route.params.id,
					obj: fd
				})
			}
			this.close();
		},

		validationСheck() {
			if (
				this.rules.required(this.editedItem.number) == true &&
				this.rules.required(this.editedItem.period) == true
			) {
				return true;
			}
		}
	}
};
</script>

<style scoped>
.categories-sort {
  width: 0px;
}

.i-link {
  text-decoration: none;
}
</style>
