<template>
	<div>
		<v-btn v-on:click="goHome" class="mr-2" color="primary" outlined dark>
			Вернуться на главную
		</v-btn>
		<v-btn v-on:click="goBack" color="primary" dark>
			Назад
		</v-btn>
	</div>
</template>

<script>
export default {
	methods: {
		goHome() {
			this.$router.push({name: "Home"})
		},
		goBack() {
			this.$router.go(-1)
		}
	}
};
</script>
