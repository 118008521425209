import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import axios from "axios";

Vue.config.productionTip = false;

const token = localStorage.getItem('access_token')

if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
	store.commit('SET_FLAG')
}

axios.interceptors.request.use(
	function(config) {
		// Do something before request is sent
		//console.log('Request:', config);
		return config;
	},
	function(error) {
		// Do something with request error
		//console.log(error);
		return Promise.reject(error);
	}
);

// Add a response interceptor
axios.interceptors.response.use(
	function(response) {
		// Do something with response data
		// console.log('Response:', response);
		return response;
	},
	function(error) {
		// Do something with response error
		const rt = localStorage.getItem('refresh_token');

		if (error.response.status === 401 && rt !== null)
			store.dispatch('REFRESH'); // refresh jwt token

		return Promise.reject(error);
	}
);

new Vue({
	store,
	router,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount("#app")
