<template>
  <div>
    <v-container>
      <alert-bar :snackbar="alertInfo" />
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <data-table />
    </v-container>
  </div>
</template>

<script>
// data table
import DataTable from "@/components/data-tables/articles/Theories";

// alert bar for message
import AlertBar from "@/components/AlertBar";

export default {
	name: "Theories",
	data() {
		return {
			breadcrumbs: []
		};
	},
	computed: {
		alertInfo() {
			return this.$store.getters.THEORY_STATUS;
		}
	},
	components: {
		DataTable,
		AlertBar
	}
};
</script>
