import axios from "axios";

export default {
	state: {
		files: [],
		fileStatus: {}, // response obj
		filesPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_FILES_TO_STATE: (state, files) => {
			state.files = files;
		},
		SET_FILE_TO_STATE: (state, file) => {
			state.files.push(file);
		},
		UPDATE_FILE_FROM_STATE: (state, file) => {
			const index = state.files.findIndex(r => r.id === file.id);
			if (index !== -1) state.files.splice(index, 1, file);
		},
		DELETE_FILE_FROM_STATE: (state, file) => {
			state.files = state.files.filter(r => file.id !== r.id);
		},
		// set response
		SET_FILE_RESPONSE_TO_STATE: (state, response) => {
			state.fileStatus = response;
		},
		// set metadata for pagination
		SET_FILES_PAGINATION_TO_STATE: (state, response) => {
			state.filesPagination.page = response.page;
			state.filesPagination.rowsPerPage = response.per_page;
			state.filesPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of files
		GET_FILES_FROM_API({ commit }, props) {
				return axios(
					"/files?include_metadata=enable&page=" +
						props.page +
						"&per_page=" +
						props.rowsPerPage,
					{
						method: "GET"
					}
				)
				.then(files => {
					commit("SET_FILES_TO_STATE", files.data.items);
					commit(
						"SET_FILES_PAGINATION_TO_STATE",
						files.data._metadata.pagination
					);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// download file by id
		DOWNLOAD_FILE_FROM_API({ commit }, file) {
			return axios("/files/download/" + file.id, {
				method: "GET",
				responseType: "blob"
			})
				.then(response => {
					console.log(commit); console.clear();
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");

					let words = response.data.type.split("/");

					link.href = url;
					link.setAttribute("download", `file.${words[1]}`);
					document.body.appendChild(link);
					link.click();
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// download thumbnail file by id
		DOWNLOAD_THUMBNAIL_FILE_FROM_API({ commit }, file) {
			return axios("/files/download/" + file.id + "/thumbnail", {
				method: "GET",
				responseType: "blob"
			})
				.then(response => {
					console.log(commit); console.clear();
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");

					let words = response.data.type.split("/");

					link.href = url;
					link.setAttribute("download", `file.${words[1]}`);
					document.body.appendChild(link);
					link.click();
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new file
		CREATE_FILE_TO_API({ commit }, file) {
			let formData = new FormData();
			formData.append("file", file);

			return axios("/files", {
				method: "POST",
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
					"Accept": "application/json"
				}
			})
			.then(response => {
				console.log(response);
				if (response.status === 201) {
					commit("SET_FILE_TO_STATE", response.data);

					commit("SET_FILE_RESPONSE_TO_STATE", {
						text: "Файл успешно добавлен",
						type: "success",
						show: true
					});
				}
			})
			.catch(error => {
				console.log(error.response.data); // debug
				if (error.response.status === 401) {
					commit("SET_FILE_RESPONSE_TO_STATE", {
						text: "Пользователь не авторизован",
						type: "error",
						show: true
					});
				} else if (error.response.status === 422) {
					commit("SET_FILE_RESPONSE_TO_STATE", {
						text: "Ошибка обработки полученных данных",
						type: "error",
						show: true
					});
				} else {
					commit("SET_FILE_RESPONSE_TO_STATE", {
						text: "Непредвиденная ошибка",
						type: "error",
						show: true
					});
				}
			});
		},
		// update global name file
		UPDATE_FILE_FROM_API({ commit }, file) {
			return axios("/files/" + file.id + "/pin", {
				method: "PUT",
				data: { global_name: file.global_name }
			})
				.then(response => {
					if (response.status === 200) { // 201
						commit("UPDATE_FILE_FROM_STATE", response.data);

						commit("SET_FILE_RESPONSE_TO_STATE", {
							text: "Название файла успешно сохранено",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_FILE_RESPONSE_TO_STATE", {
							text: "Введите название файла",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_FILE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else if (error.response.status === 422) {
						commit("SET_FILE_RESPONSE_TO_STATE", {
							text: "Файл с таким именем уже существует",
							type: "error",
							show: true
						});
					} else {
						commit("SET_FILE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete file
		DELETE_FILE_FROM_API({ commit }, file) {
			return axios("/files/" + file.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_FILE_FROM_STATE", file);

						commit("SET_FILE_RESPONSE_TO_STATE", {
							text: "Файл успешно удален",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_FILE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_FILE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		FILES(state) {
			return state.files;
		},
		FILE_STATUS(state) {
			return state.fileStatus;
		},
		FILES_PAGINATION(state) {
			return state.filesPagination;
		}
	}
};
