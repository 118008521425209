import axios from "axios";

let uniqChars = new Date(),
	uniqUserInfo = window.navigator.userAgent,
	device_token = uniqChars + uniqUserInfo;

	device_token = window.btoa(device_token.replace(/[\u00A0-\u2666]/g, c => '&#' + c.charCodeAt(0) + ';'));

export default {
  state: {
		access_token: localStorage.getItem('access_token') || null,
		refresh_token: localStorage.getItem('refresh_token') || null,
		authStatus: {}, // response obj
		isAuth: false
	},
	mutations: {
		SET_FLAG: (state) => {
			state.isAuth = true
		},
		SET_TOKENS: (state, tokens) => {
			state.access_token = tokens.access_token
			state.refresh_token = tokens.refresh_token
			state.isAuth = true

			localStorage.setItem('access_token', tokens.access_token)
			localStorage.setItem('refresh_token', tokens.refresh_token)

			// set access token for axios
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
		},
		UNSET_TOKENS: (state) => {
			state.access_token = null
			state.refresh_token = null
			state.isAuth = false

			localStorage.removeItem("access_token");
			localStorage.removeItem("refresh_token");

			// unset access token for axios
			axios.defaults.headers.common['Authorization'] = null
		},
		// set response
		SET_SIGNIN_RESPONSE_TO_STATE: (state, response) => {
			state.authStatus = response;
		},
	},
	actions: {
		BASE_AUTH({commit}) {
			return axios.post('/login',
				{
					device_token
				},
				{
					auth: {
						username: 'admin@nucleus.admin',
						password: 'secret1'
					}
				}
			)
			.then(response => {
				commit('SET_TOKENS', response.data)
			})
			.catch(error => {
				commit('UNSET_TOKENS')
				console.log(error.response.data); // debug
			})
		},
		// signin
		SIGNIN({commit}, auth) {
			return axios.post('/login',
				{ device_token },
				{ auth }
			)
			.then(response => {
				console.log('welcome in da house');
				commit('SET_TOKENS', response.data)
			})
			.catch(error => {
				commit('UNSET_TOKENS')
				console.log(error.response.data); // debug
				if (error.response.status === 400) {
					commit("SET_SIGNIN_RESPONSE_TO_STATE", {
						text: "Неверный логин или пароль",
						type: "error",
						show: true
					});
				} else if (error.response.status === 401) {
					commit("SET_SIGNIN_RESPONSE_TO_STATE", {
						text: "Пользователь не авторизован",
						type: "error",
						show: true
					});
				} else {
					commit("SET_SIGNIN_RESPONSE_TO_STATE", {
						text: "Непредвиденная ошибка",
						type: "error",
						show: true
					});
				}
			})
		},
		REFRESH({commit}) {
			console.log('action refresh');
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('refresh_token')
			return axios.put('/renew'	)
			.then(response => {
				commit('SET_TOKENS', response.data)
			})
			.catch(error => {
				commit('UNSET_TOKENS')
				console.log(error.response.data); // debug
			})
		},
		LOGOUT({commit}) {
			commit("UNSET_TOKENS");
		},
	},
	getters: {
		ACCESS_TOKEN(state) {
			return state.access_token
		},
		REFRESH_TOKEN(state) {
			return state.refresh_token
		},
		AUTH_STATUS(state) {
			return state.authStatus
		},
		IS_AUTH(state) {
			return state.isAuth;
		}
	}
}
