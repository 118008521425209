<template>
  <div>
    <alert-bar :snackbar="alertInfo" />
    <v-form v-model="valid" @submit.prevent="signin">
      <v-card class="mx-auto mt-5" max-width="345" outlined>
        <v-card-title> Войти </v-card-title>
        <v-card-text
          ><v-text-field
            color="info"
            v-model="username"
            :rules="nameRules"
            label="E-mail"
            append-icon="mdi-email"
            outlined
            required
            dense
          ></v-text-field>

          <v-text-field
            color="info"
            type="password"
            v-model="password"
            :rules="nameRules"
            label="Пароль"
            append-icon="mdi-lock"
            outlined
            required
            dense
            @click:append="test = !test"
          ></v-text-field>

          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            block
            large
            dark
            >Войти</v-btn
          >
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
// alert bar for message
import AlertBar from "@/components/AlertBar";

export default {
	name: "Signin",

	data() {
		return {
			loading: false,
			valid: false,
			username: null,
			password: null,
			nameRules: [v => !!v || "Обязательное поле"]
		};
	},
	computed: {
		alertInfo() {
			return this.$store.getters.AUTH_STATUS;
		}
	},
	methods: {
		signin() {
			this.loading = true

			this.$store.dispatch("SIGNIN", {
				username: this.username,
				password: this.password
			})
			.then(() => {
				this.loading = false;

				this.$store.dispatch('GET_ROLES_FROM_API', null)
				.then(() => {
					if (this.$store.getters.IS_AUTH)
						this.$router.push({ name: "home" });
				});
			})
			.catch(() => console.log('catch'));
		}
	},
	components: {
		AlertBar
	}
};
</script>
