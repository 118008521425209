import axios from "axios";

export default {
	state: {
		poll: {},
		polls: [],
		pollStatus: {}, // response obj
		pollsPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_POLLS_TO_STATE: (state, items) => {
			state.polls = items;
		},
		SET_POLL_TO_STATE: (state, item) => {
			state.polls.push(item);
		},
		SET_POLL: (state, item) => {
			state.poll = item
		},
		UPDATE_POLL_FROM_STATE: (state, item) => {
			const index = state.polls.findIndex(r => r.id === item.id);
			if (index !== -1) state.polls.splice(index, 1, item);
		},
		DELETE_POLL_FROM_STATE: (state, item) => {
			state.polls = state.polls.filter(r => item.id !== r.id);
		},
		// set response
		SET_POLL_RESPONSE_TO_STATE: (state, response) => {
			state.pollStatus = response;
		},
		// set metadata for pagination
		SET_POLLS_PAGINATION_TO_STATE: (state, response) => {
			state.pollsPagination.page = response.page;
			state.pollsPagination.rowsPerPage = response.per_page;
			state.pollsPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of polls
		GET_POLLS_FROM_API({ commit }, params) {

			let concat = null
			params !== null
				? concat = `
					?include_metadata=enable
					&page=${ params.page }
					&per_page=${ params.rowsPerPage }
					&category_id=${ params.category_id }
					&is_published=${ params.is_published }`
				: concat = '';

			return axios(`/polls${ concat }`,
				{
					method: "GET"
				}
			)
			.then(polls => {
				commit("SET_POLLS_TO_STATE", polls.data.items);
				commit(
					"SET_POLLS_PAGINATION_TO_STATE",
					polls.data._metadata.pagination
				);
			})
			.catch(error => {
				console.log(error.response.data); // debug
			});
		},
		// return poll by id
		GET_POLL_FROM_API({ commit }, id) {
			return axios("/polls/" + id, {
				method: "GET"
			})
				.then(polls => {
					commit("SET_POLL", polls.data);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new poll
		CREATE_POLL_TO_API({ commit }, poll) {
			return axios("/polls", {
				method: "POST",
				data: poll
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_POLL_TO_STATE", response.data);

						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Опрос успешно добавлен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update poll
		UPDATE_POLL_FROM_API({ commit }, poll) {
			return axios("/polls/" + poll.id, {
				method: "PUT",
				data: poll
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_POLL_FROM_STATE", response.data);

						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Опрос успешно обновлен",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete poll
		DELETE_POLL_FROM_API({ commit }, poll) {
			return axios("/polls/" + poll.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_POLL_FROM_STATE", poll);

						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Опрос успешно удален",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_POLL_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		POLL(state) {
			return state.poll;
		},
		POLLS(state) {
			return state.polls;
		},
		POLL_STATUS(state) {
			return state.pollStatus;
		},
		POLLS_PAGINATION(state) {
			return state.pollsPagination;
		}
	}
};
