import axios from "axios";

export default {
	state: {
		category: {},
		categories: [],
		categoryStatus: {}, // response obj
		categoryPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_CATEGORIES_TO_STATE: (state, categories) => {
			state.categories = categories;
		},
		SET_CATEGORY_TO_STATE: (state, category) => {
			state.categories.push(category);
		},
		SET_CATEGORY: (state, category) => {
			state.category = category
		},
		UPDATE_CATEGORY_FROM_STATE: (state, category) => {
			const index = state.categories.findIndex(r => r.id === category.id);
			if (index !== -1) state.categories.splice(index, 1, category);
		},
		DELETE_CATEGORY_FROM_STATE: (state, category) => {
			state.categories = state.categories.filter(r => category.id !== r.id);
		},
		// set response
		SET_CATEGORY_RESPONSE_TO_STATE: (state, response) => {
			state.categoryStatus = response;
		},
		// set metadata for pagination
		SET_CATEGORIES_PAGINATION_TO_STATE: (state, response) => {
			state.categoryPagination.page = response.page;
			state.categoryPagination.rowsPerPage = response.per_page;
			state.categoryPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of categories
		GET_CATEGORIES_FROM_API({ commit }, params) {

			let concat = null
			params !== null
				? concat = `?include_metadata=enable&page=${ params.pagination.page }&per_page=${ params.pagination.itemsPerPage }&content_type=${ params.section_id }`
				: concat = '';

			return axios(`/directories/categories${ concat }`, {
				method: "GET"
			})
				.then(categories => {
					commit("SET_CATEGORIES_TO_STATE", categories.data.items);

					if (concat) {
						commit(
							"SET_CATEGORIES_PAGINATION_TO_STATE",
							categories.data._metadata.pagination
						);
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// return theory by id
		GET_CATEGORY_FROM_API({ commit }, id) {
			return axios(`directories/categories/${ id }`, {
				method: "GET"
			})
				.then(items => {
					commit("SET_CATEGORY", items.data);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new category
		CREATE_CATEGORY_TO_API({ commit }, category) {
			return axios("/directories/categories", {
				method: "POST",
				data: category
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_CATEGORY_TO_STATE", response.data);

						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Категория успешно добавлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else if (error.response.data.description === "Object exists.") {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Такая категория уже существует",
							type: "error",
							show: true
						});
					} else {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update category
		UPDATE_CATEGORY_FROM_API({ commit }, category) {
			return axios("/directories/categories/" + category.id, {
				method: "PUT",
				data: category
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_CATEGORY_FROM_STATE", response.data);

						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Категория успешно обновлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else if (error.response.data.description === "Object exists.") {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Такая категория уже существует",
							type: "error",
							show: true
						});
					} else {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete category
		DELETE_CATEGORY_FROM_API({ commit }, category) {
			return axios("/directories/categories/" + category.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_CATEGORY_FROM_STATE", category);

						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Категория успешно удалена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_CATEGORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		CATEGORY(state) {
			return state.category || {}
		},
		CATEGORIES(state) {
			return state.categories;
		},
		CATEGORY_STATUS(state) {
			return state.categoryStatus;
		},
		CATEGORIES_PAGINATION(state) {
			return state.categoryPagination;
		}
	}
};
