import axios from "axios";

export default {
	state: {
		theory: {},
		theories: [],
		theoryStatus: {}, // response obj
		theoryPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_THEORIES_TO_STATE: (state, theories) => {
			state.theories = theories;
		},
		SET_THEORY_TO_STATE: (state, theory) => {
			state.theories.push(theory);
		},
		SET_THEORY: (state, theory) => {
			state.theory = theory
		},
		UPDATE_THEORY_FROM_STATE: (state, theory) => {
			const index = state.theories.findIndex(r => r.id === theory.id);
			if (index !== -1) state.theories.splice(index, 1, theory);
		},
		DELETE_THEORY_FROM_STATE: (state, theory) => {
			state.theories = state.theories.filter(r => theory.id !== r.id);
		},
		// set response
		SET_THEORY_RESPONSE_TO_STATE: (state, response) => {
			state.theoryStatus = response;
		},
		// set metadata for pagination
		SET_THEORIES_PAGINATION_TO_STATE: (state, response) => {
			state.theoryPagination.page = response.page;
			state.theoryPagination.rowsPerPage = response.per_page;
			state.theoryPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of theories
		GET_THEORIES_FROM_API({ commit }, params) {
			return axios(`/theories?include_metadata=enable&page=${ params.page }&per_page=${ params.itemsPerPage }&is_published=${ params.is_published }`, {
				method: "GET"
			})
				.then(theories => {
					commit("SET_THEORIES_TO_STATE", theories.data.items);
					commit(
						"SET_THEORIES_PAGINATION_TO_STATE",
						theories.data._metadata.pagination
					);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// return theory by id
		GET_THEORY_FROM_API({ commit }, id) {
			return axios(`theories/${ id }`, {
				method: "GET"
			})
				.then(items => {
					commit("SET_THEORY", items.data);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new theory
		CREATE_THEORY_TO_API({ commit }, theory) {
			return axios("/theories", {
				method: "POST",
				data: theory
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_THEORY_TO_STATE", response.data);

						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Теория успешно добавлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 400) {
						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.data.code === 401) {
						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update theory
		UPDATE_THEORY_FROM_API({ commit }, theory) {
			return axios("/theories/" + theory.id, {
				method: "PUT",
				data: theory
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_THEORY_FROM_STATE", response.data);

						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Теория успешно обновлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 400) {
						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.data.code === 401) {
						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete theory
		DELETE_THEORY_FROM_API({ commit }, theory) {
			return axios("/theories/" + theory.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_THEORY_FROM_STATE", theory);

						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Теория успешно удалена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_THEORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		THEORY(state) {
			return state.theory || {}
		},
		THEORIES(state) {
			return state.theories;
		},
		THEORY_STATUS(state) {
			return state.theoryStatus;
		},
		THEORIES_PAGINATION(state) {
			return state.theoryPagination;
		}
	}
};
