import axios from "axios";

export default {
	state: {
		program: {},
		programs: [],
		programStatus: {}, // response obj
		programsPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_PROGRAMS_TO_STATE: (state, programs) => {
			state.programs = programs;
		},
		SET_PROGRAM_TO_STATE: (state, program) => {
			state.programs.push(program);
		},
		SET_PROGRAM: (state, program) => {
			state.program = program
		},
		UPDATE_PROGRAM_FROM_STATE: (state, program) => {
			const index = state.programs.findIndex(r => r.id === program.id);
			if (index !== -1) state.programs.splice(index, 1, program);
		},
		DELETE_PROGRAM_FROM_STATE: (state, program) => {
			state.programs = state.programs.filter(r => program.id !== r.id);
		},
		// set response
		SET_PROGRAM_RESPONSE_TO_STATE: (state, response) => {
			state.programStatus = response;
		},
		// set metadata for pagination
		SET_PROGRAMS_PAGINATION_TO_STATE: (state, response) => {
			state.programsPagination.page = response.page;
			state.programsPagination.rowsPerPage = response.per_page;
			state.programsPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of programs
		GET_PROGRAMS_FROM_API({ commit }, params) {

			let concat = null
			params !== null ? concat = `?include_metadata=enable&page=${ params.page }&per_page=${ params.itemsPerPage }` : concat = '';

			return axios(`/programs${ concat }`,
				{
					method: "GET"
				}
			)
			.then(programs => {
				commit("SET_PROGRAMS_TO_STATE", programs.data.items);
				commit(
					"SET_PROGRAMS_PAGINATION_TO_STATE",
					programs.data._metadata.pagination
				);
			})
			.catch(error => {
				console.log(error.response.data); // debug
			});
		},
		// return program by id
		GET_PROGRAM_FROM_API({ commit }, id) {
			return axios("/programs/" + id, {
				method: "GET"
			})
				.then(programs => {
					commit("SET_PROGRAM", programs.data);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new program
		CREATE_PROGRAM_TO_API({ commit }, program) {
			return axios("/programs", {
				method: "POST",
				data: program
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_PROGRAM_TO_STATE", response.data);

						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Программа успешно добавлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update program
		UPDATE_PROGRAM_FROM_API({ commit }, program) {
			return axios("/programs/" + program.id, {
				method: "PUT",
				data: program
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_PROGRAM_FROM_STATE", response.data);

						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Программа успешно обновлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete program
		DELETE_PROGRAM_FROM_API({ commit }, program) {
			return axios("/programs/" + program.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_PROGRAM_FROM_STATE", program);

						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Программа успешно удалена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_PROGRAM_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		PROGRAM(state) {
			return state.program;
		},
		PROGRAMS(state) {
			return state.programs;
		},
		PROGRAM_STATUS(state) {
			return state.programStatus;
		},
		PROGRAMS_PAGINATION(state) {
			return state.programsPagination;
		}
	}
};
