<template>
	<div>
		<v-container>
			<v-breadcrumbs :items="breadcrumbs" divider=""></v-breadcrumbs>
			<div class="d-flex flex-column align-center justify-center">
				<h1>Ошибка 404. Страница не найдена</h1>
				<p>
					К сожалению, запрашиваемая вами страница отсутствует или у неё
					изменился адрес
				</p>
				<navigation-block />
			</div>
		</v-container>
	</div>
</template>

<script>
import NavigationBlock from "@/components/NavigationBlock";

export default {
	name: "Page404",
	data() {
		return {
			breadcrumbs: [
				{
					text: " ",
					disabled: true
				}
			]
		};
	},
	components: {
		NavigationBlock
	}
};
</script>

<style></style>
