<template>
		<v-snackbar
			v-model="snackbar.show"
			:timeout="3000"
			:color="snackbar.type"
			transition="slide-y-reverse-transition"
		>
			{{ snackbar.text }}
		</v-snackbar>
</template>

<script>
export default {
	name: "AlertBar",
	props: {
		snackbar: {
			type: Object
		}
	}
};
</script>
