<template>
	<div>
		<v-form v-model="valid" @submit.prevent="signup">
			<v-container>
				<v-row>
					<v-col cols="4"></v-col>
					<v-col cols="4">
						<h1 class="mb-2">Регистрация</h1>
						<v-text-field
							v-model="email"
							:rules="nameRules"
							label="E-mail"
							outlined
							required
							dense
						></v-text-field>

						<v-text-field
							type="mobile_phone"
							v-model="mobile_phone"
							:rules="nameRules"
							label="Телефон"
							outlined
							required
							dense
						></v-text-field>

						<v-text-field
							type="password"
							v-model="password"
							:rules="nameRules"
							label="Пароль"
							outlined
							required
							dense
						></v-text-field>

						<v-btn type="submit" block large dark color="primary">Войти</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
export default {
	name: "Signup",

	data() {
		return {
			valid: false,
			email: "",
			mobile_phone: "",
			password: "",
			nameRules: [v => !!v || "Обязательное поле"]
		};
	},
	methods: {
		signup() {
			// this.$store.dispatch("SIGNIN", {
			// 	username: this.username,
			// 	password: this.password
			// });
		}
	},
	components: {
		//
	}
};
</script>
