<template>
  <v-data-table
    :loading="loading"
    loading-text="Пожалуйста подождите..."
    :headers="headers"
    :items="PRACTICES"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15, 30],
      itemsPerPageText: 'Строк на странице',
      itemsPerPageAllText: 'Все',
      pageText: '{0} - {1} из {2}',
    }"
    :options.sync="pagination"
    :server-items-length="pagination.totalItems"
    @update:options="initialize"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Практика</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Создать практику
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-select
                :items="types"
                color="info"
                item-color="info"
                v-model="editedItem.type"
                item-value="id"
                item-text="name"
                label="Тип *"
                :rules="[rules.required]"
                required
                dense
                outlined
              ></v-select>
              <v-select
                @change="setSubcategories"
                :items="categories"
                color="info"
                item-color="info"
                v-model="editedItem.category_id"
                item-value="id"
                item-text="name"
                label="Категория *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-select>
              <v-select
                :items="subcategories"
                color="info"
                item-color="info"
                v-model="editedItem.subcategory_id"
                item-value="id"
                item-text="name"
                label="Подкатегория *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-select>
              <v-text-field
                color="info"
                v-model="editedItem.title"
                label="Заголовок *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-text-field>
              <v-sheet>
                <span>Схема</span>
                <v-slide-group
                  v-model="editedItem.main_picture_id"
                  class="py-4 pt-2"
                  center-active
                  show-arrows
                >
                  <v-slide-item
                    v-for="file in images_ids"
                    :key="file.id"
                    :value="file.id"
                    v-slot="{ active, toggle }"
                  >
                    <v-card
                      :color="active ? 'info' : 'grey lighten-1'"
                      class="ma-1"
                      height="100"
                      width="100"
                      style="overflow: hidden"
                      @click="toggle"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-img height="80" :src="file.thumbnail"></v-img>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <v-sheet>
                <span>Видео</span>
                <v-slide-group
                  v-model="editedItem.video_ids"
                  class="py-4 pt-2"
                  center-active
                  show-arrows
                  multiple
                >
                  <v-slide-item
                    v-for="file in video_ids"
                    :key="file.id"
                    :value="file.id"
                    v-slot="{ active, toggle }"
                  >
                    <v-card
                      :color="active ? 'info' : 'grey lighten-1'"
                      class="ma-1"
                      height="100"
                      width="100"
                      style="overflow: hidden"
                      @click="toggle"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-img height="80" :src="file.thumbnail"></v-img>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <v-textarea
                color="info"
                v-model="editedItem.content"
                label="Контент"
                outlined
                dense
              ></v-textarea>
              <v-text-field
                type="number"
                color="info"
                v-model="editedItem.duration"
                label="Продолжительность (мин.) *"
                :rules="[rules.duration]"
                required
                min="1"
                max="5"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                color="info"
                v-model="editedItem.guidelines"
                label="Методические указания"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                color="info"
                v-model="editedItem.inventory"
                label="Инвентарь"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                type="number"
                color="info"
                v-model="editedItem.min_age"
                label="Минимальный возраст *"
                :rules="[rules.min_age]"
                required
                min="3"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                type="number"
                color="info"
                v-model="editedItem.number_players"
                label="Число игроков"
                :rules="[rules.number_players]"
                required
                min="1"
                outlined
                dense
              ></v-text-field>
              <v-select
                :items="part_fields"
                color="info"
                item-color="info"
                v-model="editedItem.part_field"
                item-value="name"
                item-text="name"
                label="Часть поля *"
                dense
                outlined
              ></v-select>
              <v-select
                :items="places"
                color="info"
                item-color="info"
                v-model="editedItem.place"
                item-value="id"
                item-text="name"
                label="Место проведения практики *"
                :rules="[rules.required]"
                dense
                outlined
              ></v-select>
              <v-checkbox
                v-model="editedItem.is_published"
                label="Опубликовать"
                color="info"
                hide-details
              ></v-checkbox>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="close"> Отмена </v-btn>
              <v-btn
                color="primary"
                @click="save"
                :disabled="!validationСheck()"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShow" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{
              editedItem.announce
            }}</v-card-title>
            <v-card-text>
              <p>{{ editedItem.title }}</p>
              <p>{{ editedItem.content }}</p>
              <!-- <v-img :src="editedItem.main_picture.thumbnail" alt="" /> -->
              <v-list-item style="padding: 0">
                <!-- <v-list-item-avatar
									v-if="editedItem.author_picture"
									color="grey darken-3"
								>
									<v-img
										class="elevation-6"
										:src="editedItem.author_picture.thumbnail"
									></v-img>
								</v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title>{{ editedItem.author }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeShow">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogElements" scrollable max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ hotItem.title }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-select
                :items="practicesElements"
                v-model="elementItem.element_id"
                item-value="id"
                item-text="title"
                label="Элемент *"
                dense
                outlined
              ></v-select>

              <v-list v-if="PRACTICE.elements && PRACTICE.elements.length > 0">
                <v-list-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Элементы</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <transition-group>
                    <v-list-item
                      v-for="child in PRACTICE.elements"
                      :key="child.title"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="child.content"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="unpinElement(child, 'preparatory')">
                          <v-icon color="secondary">mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition-group>
                </v-list-group>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="closeElements">
                Отмена
              </v-btn>
              <v-btn color="primary" dark @click="pinElements">
                Прикрепить элемент
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Удаление</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите удалить эту практику?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="deleteItemConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogElDelete" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Удаление</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите открепить этот элемент?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="unpinElementConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeElDelete">
                Отмена
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        v-if="item.type !== `elements`"
        x-small
        color="info mr-2"
        @click="elementsItem(item)"
        >Элементы</v-btn
      >
      <v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
        mdi-eye
      </v-icon>
      <v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon dense color="secondary" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		dialogElements: false,
		dialogElDelete: false,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Название", value: "title" },
			{ text: "Продолжительность", value: "duration" },
			{ text: "Минимальный возраст", value: "min_age" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле",
			duration: value => {
				const pattern = /^([1-5]|1[19])$/;
				return pattern.test(value) || "Некорректная продолжительность";
			},
			min_age: value => {
				const pattern = /^[3,1-9][0-9]?$|^100$/;
				return pattern.test(value) || "Некорректный возраст";
			},
			number_players: value => {
				const pattern = /^[1-9][0-9]?$|^100$/;
				return pattern.test(value) || "Некорректное количество игроков";
			}
		},
		types: [
			{
				id: "elements",
				name: "Элементы"
			},
			{
				id: "games",
				name: "Игры"
			},
			{
				id: "exercises",
				name: "Упражнения"
			}
		],
		part_fields: [
			{
				name: "1/4"
			},
			{
				name: "1/2"
			},
			{
				name: "1/1"
			}
		],
		places: [
			{
				id: "ice",
				name: "Лёд"
			},
			{
				id: "out_ice",
				name: "Вне льда"
			}
		],
		// PRACTICES: [],
		editedIndex: -1,
		editedItem: {
			category_id: null,
			duration: 1,
			guidelines: null,
			inventory: null,
			is_published: false,
			main_picture_id: null,
			//main_video_id: null,
			min_age: 3,
			number_players: 1,
			part_field: null,
			place: null,
			subcategory_id: null,
			title: null,
			type: null,
			video_ids: []
		},
		defaultItem: {
			category_id: null,
			duration: 1,
			guidelines: null,
			inventory: null,
			is_published: false,
			main_picture_id: null,
			//main_video_id: null,
			min_age: 3,
			number_players: 1,
			part_field: null,
			place: null,
			subcategory_id: null,
			title: null,
			type: null,
			video_ids: []
		},
		elementItem: {
			element_id: null
		},
		practicesElements: [],
		paginationSetter: null,
		hotItem: {},
		categories: [],
		subcategories: [],
		images_ids: [],
		video_ids: []
		// search: null
	}),

	computed: {
		...mapGetters([
			"PRACTICE",
			"PRACTICES",
			"DIRECTORIES",
			"FILES",
			"PRACTICES_PAGINATION"
		]),

		pagination: {
			get() {
				return this.PRACTICES_PAGINATION;
			},
			set() {
				this.paginationSetter = this.PRACTICES_PAGINATION;
			}
		},

		// mediaChecked(media) {
		// 	this.images_ids.forEach(item => {

		// 		if (item.id === media.id) {
		// 			console.log('yes');
		// 			return true

		// 		}
		// 	})
		// },

		formTitle() {
			return this.editedIndex === -1 ? "Создать практику" : "Обновить практику";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_PRACTICES_FROM_API",
			"GET_PRACTICE_FROM_API",
			"CREATE_PRACTICE_TO_API",
			"UPDATE_PRACTICE_FROM_API",
			"DELETE_PRACTICE_FROM_API",
			// images
			"GET_FILES_FROM_API",
			// v-select, images
			"GET_DIRECTORIES_FROM_API",
			// elements
			"PIN_ELEMENT_TO_API",
			"UNPIN_ELEMENT_FROM_API"
		]),



		initialize(props) {
			this.loading = true;
			this.GET_PRACTICES_FROM_API({
				pagination: props // pagination
			}).then(() => (this.loading = false));

			this.GET_DIRECTORIES_FROM_API().then(() => {
				this.categories = this.DIRECTORIES.practices.categories;

				this.video_ids = [];
				this.images_ids = [];

				for (let key in this.DIRECTORIES.files) {
					this.DIRECTORIES.files[key].mime_type === "video/mp4"
						? this.video_ids.push(this.DIRECTORIES.files[key])
						: this.images_ids.push(this.DIRECTORIES.files[key]);
				}
			});
		},

		elementsItem(item) {
			this.hotItem = item;

			this.loading = true;
			this.GET_PRACTICE_FROM_API(this.hotItem.id).then(() => {
				this.loading = false;

				this.practicesElements = [];
				this.PRACTICES.forEach(item => {
					if (item.type === "elements")
						this.practicesElements.push(item)
				})
			});

			this.dialogElements = true;
		},

		showItem(item) {
			this.editedIndex = this.PRACTICES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.PRACTICES.indexOf(item);
			//this.editedItem = Object.assign({}, item);
			this.GET_PRACTICE_FROM_API(item.id).then(() => {
				this.editedItem = this.PRACTICE;
				this.setSubcategories(this.editedItem.category_id)
			});
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.PRACTICES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_PRACTICE_FROM_API(this.editedItem);
			this.PRACTICES.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		unpinElement(item) {
			// this.editedIndex = this.LESSON.elements.preparatory.indexOf(item);
			this.elementItem.element_id = item.id;

			this.dialogElDelete = true;
		},

		unpinElementConfirm() {
			this.UNPIN_ELEMENT_FROM_API({
				practice_id: this.PRACTICE.id,
				element_id: this.elementItem.element_id,
				obj: {
					element_id: this.elementItem.element_id
				}
			})
			.then(() => {
				this.GET_PRACTICE_FROM_API(this.hotItem.id);
			});

			this.closeElDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeElDelete() {
			this.dialogElDelete = false;
		},

		closeElements() {
			this.dialogElements = false;
		},

		pinElements() {
			let fd = { ...this.elementItem };

			this.PIN_ELEMENT_TO_API({
				practice_id: this.hotItem.id,
				obj: fd
			})
			.then(() => {
				this.GET_PRACTICE_FROM_API(this.hotItem.id);
			});
		},

		save() {
			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };
				delete fd.main_video;
				delete fd.main_picture;
				delete fd.videos;
				delete fd.elements;

				fd.duration = Number(fd.duration);
				fd.min_age = Number(fd.min_age);
				fd.number_players = Number(fd.number_players);

				for (let key in fd)
					if (fd[key] === '' || fd[key] === null)
						delete fd[key];

				this.UPDATE_PRACTICE_FROM_API(fd);
				Object.assign(this.PRACTICES[this.editedIndex], this.editedItem);
			} else {
				let fd = { ...this.editedItem };

				fd.duration = Number(fd.duration);
				fd.min_age = Number(fd.min_age);
				fd.number_players = Number(fd.number_players);

				for (let key in fd)
					if (fd[key] === '' || fd[key] === null)
						delete fd[key];

				if (fd.video_ids.length === 0) delete fd.video_ids

				this.CREATE_PRACTICE_TO_API(fd);
			}
			this.close();
		},

		setSubcategories(category_id) {
			this.categories.forEach(el => {
				if (el.id === category_id) this.subcategories = el.subcategories;
			});

			if (!this.subcategories) this.subcategories = [];
		},

		validationСheck() {
			if (
				this.rules.required(this.editedItem.type) == true &&
				this.rules.required(this.editedItem.category_id) == true &&
				this.rules.required(this.editedItem.subcategory_id) == true &&
				this.rules.required(this.editedItem.title) == true &&
				this.rules.duration(this.editedItem.duration) == true &&
				this.rules.min_age(this.editedItem.min_age) == true &&
				this.rules.number_players(this.editedItem.number_players) &&
				this.rules.required(this.editedItem.place) == true
			) {
				return true;
			}
		}
	}
};
</script>
