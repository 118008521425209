import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#DA291C",    // red
				secondary: "#1D428A",  // dark azure
				accent: "#E8564C",     // cinnabar
				error: "#FF5252",      // light red
				info: "#2196F3",       // light blue
				success: "#4CAF50",    // pastel-green
				warning: "#FFC107"     // amber
			}
		}
	}
});
