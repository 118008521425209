<template>
  <v-data-table
    :loading="loading"
    loading-text="Пожалуйста подождите..."
    :headers="headers"
    :items="THEORIES"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15, 30],
      itemsPerPageText: 'Строк на странице',
      itemsPerPageAllText: 'Все',
      pageText: '{0} - {1} из {2}',
    }"
    :options.sync="pagination"
    :server-items-length="pagination.totalItems"
    @update:options="initialize"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Теория</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-switch
          class="mt-3 mr-3"
          v-model="publishFilter"
          label="Опубликованные"
          @change="toggleActivePublished"
        ></v-switch>
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Создать теорию
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-select
                @change="setSubcategories"
                :items="categories"
                color="info"
                v-model="editedItem.category_id"
                item-value="id"
                item-text="name"
                label="Категория *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-select>
              <v-select
                v-if="editedItem.category_id && subcategories"
                :items="subcategories"
                color="info"
                v-model="editedItem.subcategory_id"
                item-value="id"
                item-text="name"
                label="Подкатегория *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-select>
              <v-text-field
                color="info"
                v-model="editedItem.announce"
                label="Анонс *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                color="info"
                v-model="editedItem.author"
                label="Автор *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-text-field>
              <v-sheet>
                <span>Фотография автора</span>
                <v-slide-group
                  v-model="editedItem.author_picture_id"
                  class="py-4 pt-2"
                  center-active
                  show-arrows
                >
                  <v-slide-item
                    v-for="file in images_ids"
                    :key="file.id"
                    :value="file.id"
                    v-slot="{ active, toggle }"
                  >
                    <v-card
                      :color="active ? 'info' : 'grey lighten-1'"
                      class="ma-1"
                      height="100"
                      width="100"
                      style="overflow: hidden"
                      @click="toggle"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-img height="80" :src="file.thumbnail"></v-img>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <v-text-field
                color="info"
                v-model="editedItem.title"
                label="Заголовок *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-text-field>
              <v-textarea
                color="info"
                v-model="editedItem.content"
                label="Контент *"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-textarea>

              <v-sheet>
                <span>Фотография</span>
                <v-slide-group
                  v-model="editedItem.main_picture_id"
                  class="py-4 pt-2"
                  center-active
                  show-arrows
                >
                  <v-slide-item
                    v-for="file in images_ids"
                    :key="file.id"
                    :value="file.id"
                    v-slot="{ active, toggle }"
                  >
                    <v-card
                      :color="active ? 'info' : 'grey lighten-1'"
                      class="ma-1"
                      height="100"
                      width="100"
                      style="overflow: hidden"
                      @click="toggle"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-img height="80" :src="file.thumbnail"></v-img>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <v-sheet>
                <span>Видео</span>
                <v-slide-group
                  v-model="editedItem.main_video_id"
                  class="py-4 pt-2"
                  center-active
                  show-arrows
                >
                  <v-slide-item
                    v-for="file in video_ids"
                    :key="file.id"
                    :value="file.id"
                    v-slot="{ active, toggle }"
                  >
                    <v-card
                      :color="active ? 'info' : 'grey lighten-1'"
                      class="ma-1"
                      height="100"
                      width="100"
                      style="overflow: hidden"
                      @click="toggle"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-img height="80" :src="file.thumbnail"></v-img>
                        <p v-if="file.global_name" class="text-center">
                          {{ file.global_name }}
                        </p>
                        <p v-else class="text-center">{{ file.name }}</p>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <v-checkbox
                color="info"
                v-model="editedItem.is_published"
                label="Опубликовать"
                hide-details
              ></v-checkbox>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="close"> Отмена </v-btn>
              <v-btn
                color="primary"
                @click="save"
                :disabled="!validationСheck()"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShow" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">
              {{ editedItem.title }}
            </v-card-title>
            <v-card-text>
              <p>{{ editedItem.announce }}</p>
              <p>{{ editedItem.content }}</p>
              <!-- <v-img :src="editedItem.main_picture.thumbnail" alt="" /> -->
              <v-list-item style="padding: 0">
                <!-- <v-list-item-avatar
									v-if="editedItem.author_picture"
									color="grey darken-3"
								>
									<v-img
										class="elevation-6"
										:src="editedItem.author_picture.thumbnail"
									></v-img>
								</v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title>{{ editedItem.author }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeShow">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Удаление</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите удалить эту теорию?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="deleteItemConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
        mdi-eye
      </v-icon>
      <v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon dense color="secondary" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		publishFilter: true,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Название", value: "announce" },
			{ text: "Автор", value: "author" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле"
		},
		// THEORIES: [],
		editedIndex: -1,
		editedItem: {
			announce: null,
			author: null,
			author_picture_id: null,
			category_id: null,
			content: null,
			is_published: false,
			main_picture_id: null,
			main_video_id: null,
			subcategory_id: null,
			title: null
		},
		defaultItem: {
			announce: null,
			author: null,
			author_picture_id: null,
			category_id: null,
			content: null,
			is_published: false,
			main_picture_id: null,
			main_video_id: null,
			subcategory_id: null,
			title: null
		},
		paginationSetter: {},
		categories: [],
		subcategories: [],
		images_ids: [],
		video_ids: []
		// search: null
	}),

	computed: {
		...mapGetters(["THEORY", "THEORIES", "DIRECTORIES", "THEORIES_PAGINATION"]),

		pagination: {
			get() {
				return this.THEORIES_PAGINATION;
			},
			set() {
				this.paginationSetter = this.THEORIES_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1 ? "Создать теорию" : "Обновить теорию";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_THEORIES_FROM_API",
			"GET_THEORY_FROM_API",
			"CREATE_THEORY_TO_API",
			"UPDATE_THEORY_FROM_API",
			"DELETE_THEORY_FROM_API",
			// v-select, images
			"GET_DIRECTORIES_FROM_API"
		]),

		initialize(props) {
			this.loading = true;
			props.is_published = this.publishFilter;

			this.GET_THEORIES_FROM_API(props).then(() => (this.loading = false));
			this.GET_DIRECTORIES_FROM_API().then(() => {
				this.categories = this.DIRECTORIES.theories.categories;

				this.video_ids = [];
				this.images_ids = [];

				for (let key in this.DIRECTORIES.files) {
					this.DIRECTORIES.files[key].mime_type === "video/mp4"
						? this.video_ids.push(this.DIRECTORIES.files[key])
						: this.images_ids.push(this.DIRECTORIES.files[key]);
				}
			});
		},

		showItem(item) {
			this.editedIndex = this.THEORIES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.THEORIES.indexOf(item);
			//this.editedItem = Object.assign({}, item);
			this.GET_THEORY_FROM_API(item.id).then(() => {
				this.editedItem = this.$store.getters.THEORY;
				this.setSubcategories(this.editedItem.category_id)
			});
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.THEORIES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_THEORY_FROM_API(this.editedItem);
			this.THEORIES.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			//this.editedItem.is_published = this.publishFilter;

			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };

				delete fd.datetime_created;
				delete fd.author_picture;
				delete fd.main_picture;
				delete fd.main_video;

				for (let key in fd)
					if (fd[key] === '' || fd[key] === null)
						delete fd[key];

				this.UPDATE_THEORY_FROM_API(fd);
				Object.assign(this.THEORIES[this.editedIndex], this.editedItem);
			} else {
				let fd = { ...this.editedItem };

				for (let key in fd)
					if (fd[key] === '' || fd[key] === null)
						delete fd[key];

				this.CREATE_THEORY_TO_API(fd);
			}
			this.close();
		},

		setSubcategories(category_id) {
			this.categories.forEach(el => {
				if (el.id === category_id) this.subcategories = el.subcategories;
			});

			if (!this.subcategories) this.subcategories = [];
		},

		toggleActivePublished() {
			this.loading = true;
			this.paginationSetter.itemsPerPage = this.paginationSetter.rowsPerPage;
			this.pagination.is_published = this.publishFilter;

			this.GET_THEORIES_FROM_API(this.pagination).then(
				() => (this.loading = false)
			);
		},

		validationСheck() {
			if (
				this.rules.required(this.editedItem.announce) == true &&
				this.rules.required(this.editedItem.author) == true &&
				this.rules.required(this.editedItem.title) == true &&
				this.rules.required(this.editedItem.content) == true &&
				this.rules.required(this.editedItem.category_id) == true
			) {
				return true;
			}
		}
	}
};
</script>
