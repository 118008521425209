import axios from "axios";

export default {
	state: {
		roles: [],
		roleStatus: {}, // response obj
		rolePagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_ROLES_TO_STATE: (state, roles) => {
			state.roles = roles;
		},
		SET_ROLE_TO_STATE: (state, role) => {
			state.roles.push(role);
		},
		UPDATE_ROLE_FROM_STATE: (state, role) => {
			const index = state.roles.findIndex(r => r.id === role.id);
			if (index !== -1) state.roles.splice(index, 1, role);
		},
		DELETE_ROLE_FROM_STATE: (state, role) => {
			state.roles = state.roles.filter(r => role.id !== r.id);
		},
		// set response
		SET_ROLE_RESPONSE_TO_STATE: (state, response) => {
			state.roleStatus = response;
		},
		// set metadata for pagination
		SET_ROLES_PAGINATION_TO_STATE: (state, response) => {
			state.rolePagination.page = response.page;
			state.rolePagination.rowsPerPage = response.per_page;
			state.rolePagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of roles
		GET_ROLES_FROM_API({ commit }, params) {

			let concat = null
			params !== null ? concat = `?include_metadata=enable&page=${ params.pagination.page }&per_page=${ params.pagination.itemsPerPage }` : concat = '';

			return axios(`/roles${ concat }`, {
				method: "GET"
			})
				.then(roles => {
					commit("SET_ROLES_TO_STATE", roles.data.items);

					if (concat) {
						commit(
							"SET_ROLES_PAGINATION_TO_STATE",
							roles.data._metadata.pagination
						);
					}
				})
				.catch(error => {
					console.log(error); // debug
				});
		},
		// create new role
		CREATE_ROLE_TO_API({ commit }, role) {
			return axios("/roles", {
				method: "POST",
				data: role
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_ROLE_TO_STATE", response.data);

						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Роль успешно добавлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update role
		UPDATE_ROLE_FROM_API({ commit }, role) {
			return axios("/roles/" + role.id, {
				method: "PUT",
				data: role
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_ROLE_FROM_STATE", response.data);

						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Роль успешно обновлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete role
		DELETE_ROLE_FROM_API({ commit }, role) {
			return axios("/roles/" + role.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_ROLE_FROM_STATE", role);

						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Роль успешно удалена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_ROLE_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		ROLES(state) {
			return state.roles;
		},
		ROLE_STATUS(state) {
			return state.roleStatus;
		},
		ROLES_PAGINATION(state) {
			return state.rolePagination;
		}
	}
};
