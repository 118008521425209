import axios from "axios";

export default {
	state: {
		feedback: {},
		feedbacks: [],
		feedbackStatus: {}, // response obj
		feedbackPagination: {
			page: 1,
			rowsPerPage: 10,
			totalItems: 0
		} // pagination
	},
	mutations: {
		SET_FEEDBACKS_TO_STATE: (state, feedbacks) => {
			state.feedbacks = feedbacks;
		},
		SET_FEEDBACK_TO_STATE: (state, feedback) => {
			state.feedbacks.push(feedback);
		},
		SET_FEEDBACK: (state, feedback) => {
			state.feedback = feedback
		},
		UPDATE_FEEDBACK_FROM_STATE: (state, feedback) => {
			const index = state.feedbacks.findIndex(r => r.id === feedback.id);
			if (index !== -1) state.feedbacks.splice(index, 1, feedback);
		},
		DELETE_FEEDBACK_FROM_STATE: (state, feedback) => {
			state.feedbacks = state.feedbacks.filter(r => feedback.id !== r.id);
		},
		// set response
		SET_FEEDBACK_RESPONSE_TO_STATE: (state, response) => {
			state.feedbackStatus = response;
		},
		// set metadata for pagination
		SET_FEEDBACKS_PAGINATION_TO_STATE: (state, response) => {
			state.feedbackPagination.page = response.page;
			state.feedbackPagination.rowsPerPage = response.per_page;
			state.feedbackPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of feedbacks
		GET_FEEDBACKS_FROM_API({ commit }, params) {

			let concat = null
			params !== null
				? concat = `?include_metadata=enable&page=${ params.pagination.page }&per_page=${ params.pagination.itemsPerPage }`
				: concat = '';

			return axios(`/feedbacks${ concat }`, {
				method: "GET"
			})
				.then(feedbacks => {
					commit("SET_FEEDBACKS_TO_STATE", feedbacks.data.items);

					if (concat) {
						commit(
							"SET_FEEDBACKS_PAGINATION_TO_STATE",
							feedbacks.data._metadata.pagination
						);
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// return theory by id
		GET_FEEDBACK_FROM_API({ commit }, id) {
			return axios(`directories/feedbacks/${ id }`, {
				method: "GET"
			})
				.then(items => {
					commit("SET_FEEDBACK", items.data);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new feedback
		CREATE_FEEDBACK_TO_API({ commit }, feedback) {
			return axios("/feedbacks", {
				method: "POST",
				data: feedback
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_FEEDBACK_TO_STATE", response.data);

						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Обратная связь успешно добавлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else if (error.response.data.description === "Object exists.") {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Такая категория уже существует",
							type: "error",
							show: true
						});
					} else {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update feedback
		UPDATE_FEEDBACK_FROM_API({ commit }, feedback) {
			return axios("/feedbacks/" + feedback.id, {
				method: "PUT",
				data: feedback
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_FEEDBACK_FROM_STATE", response.data);

						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Обратная связь успешно обновлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else if (error.response.data.description === "Object exists.") {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Такая категория уже существует",
							type: "error",
							show: true
						});
					} else {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete feedback
		DELETE_FEEDBACK_FROM_API({ commit }, feedback) {
			return axios("/feedbacks/" + feedback.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_FEEDBACK_FROM_STATE", feedback);

						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Обратная связь успешно удалена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_FEEDBACK_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		FEEDBACK(state) {
			return state.feedback || {}
		},
		FEEDBACKS(state) {
			return state.feedbacks;
		},
		FEEDBACK_STATUS(state) {
			return state.feedbackStatus;
		},
		FEEDBACKS_PAGINATION(state) {
			return state.feedbackPagination;
		}
	}
};
