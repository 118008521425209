import axios from "axios";

export default {
  state: {
		controls: []
	},
	mutations: {
		SET_CONTROLS_TO_STATE: (state, controls) => {
			state.controls = controls
		}
	},
	actions: {
		GET_CONTROLS_FROM_API({commit}) {
			return axios('/users', {
				method: 'GET'
			})
			.then(controls => {
				commit('SET_CONTROLS_TO_STATE', controls.data.items)
			})
			.catch(error => {
				console.log(error)
			})
		}
	},
	getters: {
		CONTROLS(state) {
			return state.controls;
		}
	}
}
