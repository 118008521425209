import axios from "axios";

export default {
	state: {
		lesson: {},
		lessons: [],
		lessonStatus: {}, // response obj
		lessonsPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_LESSONS_TO_STATE: (state, lessons) => {
			state.lessons = lessons;
		},
		SET_LESSON_TO_STATE: (state, lesson) => {
			state.lessons.push(lesson);
		},
		SET_LESSON: (state, lesson) => {
			state.lesson = lesson
		},
		UPDATE_LESSON_FROM_STATE: (state, lesson) => {
			const index = state.lessons.findIndex(r => r.id === lesson.id);
			if (index !== -1) state.lessons.splice(index, 1, lesson);
		},
		DELETE_LESSON_FROM_STATE: (state, lesson) => {
			state.lessons = state.lessons.filter(r => lesson.id !== r.id);
		},
		// set response
		SET_LESSON_RESPONSE_TO_STATE: (state, response) => {
			state.lessonStatus = response;
		},
		// set metadata for pagination
		SET_LESSONS_PAGINATION_TO_STATE: (state, response) => {
			state.lessonsPagination.page = response.page;
			state.lessonsPagination.rowsPerPage = response.per_page;
			state.lessonsPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of lessons
		GET_LESSONS_FROM_API({ commit }, params) {
			return axios(`programs/${ params.parent_id }/lessons?include_metadata=enable&page=${ params.pagination.page }&per_page=${ params.pagination.itemsPerPage }&category_id=${ params.category_id }`, {
				method: "GET"
			})
				.then(lessons => {
					commit("SET_LESSONS_TO_STATE", lessons.data.items);
					commit(
						"SET_LESSONS_PAGINATION_TO_STATE",
						lessons.data._metadata.pagination
					);
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// return lesson by id
		GET_LESSON_FROM_API({ commit }, params) {
			return axios(`programs/${ params.parent_id }/lessons/${ params.obj.id }`, {
				method: "GET"
			})
				.then(lessons => {
					commit("SET_LESSON", lessons.data);
				})
				.catch(error => {
					console.log(error);
				});
		},
		// create new lesson
		CREATE_LESSON_TO_API({ commit }, params) {
			return axios(`programs/${ params.parent_id }/lessons`, {
				method: "POST",
				data: params.obj
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_LESSON_TO_STATE", response.data);

						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Занятие успешно добавлено",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update lesson
		UPDATE_LESSON_FROM_API({ commit }, params) {
			return axios(`programs/${ params.parent_id }/lessons/${ params.obj.id }`, {
				method: "PUT",
				data: params.obj
			})
				.then(response => {
					if (response.status === 200) {
						commit("UPDATE_LESSON_FROM_STATE", response.data);

						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Занятие успешно обновлено",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 400) {
						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.status === 401) {
						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete lesson
		DELETE_LESSON_FROM_API({ commit }, params) {
			return axios(`programs/${ params.parent_id }/lessons/${ params.obj.id }`, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_LESSON_FROM_STATE", response.data);

						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Занятие успешно удалено",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.status === 401) {
						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_LESSON_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		LESSON(state) {
			return state.lesson || [];
		},
		LESSONS(state) {
			return state.lessons;
		},
		LESSON_STATUS(state) {
			return state.lessonStatus;
		},
		LESSONS_PAGINATION(state) {
			return state.lessonsPagination;
		}
	}
};
