<template>
  <v-data-table
    :loading="loading"
    loading-text="Пожалуйста подождите..."
    :headers="headers"
    :items="FILES"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15, 30],
      itemsPerPageText: 'Строк на странице',
      itemsPerPageAllText: 'Все',
      pageText: '{0} - {1} из {2}',
    }"
    :options.sync="pagination"
    :server-items-length="pagination.totalItems"
    @update:options="initialize"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Файлы</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Создать файл
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-file-input
                color="info"
                v-model="editedItem.file"
                label="Файл"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-file-input>
              <!-- <input type="file" @change="onFileSelected" /> -->
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="close"> Отмена </v-btn>
              <v-btn color="primary" :loading="btn" @click="save">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogGlobalName" scrollable max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <v-text-field
                color="info"
                v-model="editedItem.global_name"
                label="Глобальное имя"
                outlined
                dense
              ></v-text-field>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="close"> Отмена </v-btn>
              <v-btn color="primary" dark @click="saveGlobalName">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShow" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">
              <span>{{ editedItem.name }}</span>
            </v-card-title>
            <v-card-text>
              <span
                >Глобальное имя: <b>{{ editedItem.global_name }}</b></span
              >
              <v-img :src="editedItem.thumbnail" alt="" />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeShow">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Удаление</span>
            </v-card-title>
            <v-card-text>
              Вы действительно хотите удалить этот файл?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="deleteItemConfirm">
                Да
              </v-btn>
              <v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.downloads="{ item }">
      <v-icon dense class="mr-2" color="secondary" @click="downloadFile(item)">
        mdi-cloud-download
      </v-icon>
      <v-icon
        dense
        class="mr-2"
        color="secondary"
        @click="downloadFileThumbnail(item)"
      >
        mdi-cloud-download-outline
      </v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
        mdi-eye
      </v-icon>
      <v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon dense color="secondary" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		dialogGlobalName: false,
		selectedFile: {},
		headers: [
			{ text: "Глобальное имя", value: "global_name" },
			{ text: "Название файла", value: "name" },
			{ text: "Скачать", value: "downloads", sortable: false },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле"
		},
		// FILES: [],
		editedIndex: -1,
		editedItem: {
			file: null
		},
		defaultItem: {
			global_name: null
		},
		paginationSetter: {},
		btn: false
	}),

	computed: {
		...mapGetters(["FILES", "FILES_PAGINATION"]),

		pagination: {
			get() {
				return this.FILES_PAGINATION;
			},
			set() {
				this.paginationSetter = this.FILES_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1 ? "Создать файл" : "Обновить файл";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_FILES_FROM_API",
			"CREATE_FILE_TO_API",
			"UPDATE_FILE_FROM_API",
			"DELETE_FILE_FROM_API",
			"DOWNLOAD_FILE_FROM_API",
			"DOWNLOAD_THUMBNAIL_FILE_FROM_API"
		]),

		initialize(props) {
			this.loading = true;
			this.GET_FILES_FROM_API(props).then(() => (this.loading = false));
		},

		showItem(item) {
			this.editedIndex = this.FILES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.FILES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogGlobalName = true;
		},

		deleteItem(item) {
			this.editedIndex = this.FILES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_FILE_FROM_API(this.editedItem).then(() => {
				// update page, perPage for datatable
				this.GET_FILES_FROM_API(this.paginationSetter).then(() => (this.loading = false));
			});
			this.FILES.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.dialogGlobalName = false; //
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			this.btn = true;

			this.CREATE_FILE_TO_API(this.editedItem.file).then(() => {
				this.btn = false;

				// update page, perPage for datatable
				this.GET_FILES_FROM_API(this.paginationSetter).then(() => (this.loading = false));
				this.close();
			});
		},

		validationСheck() {
			if (this.rules.required(this.editedItem.file) == true) {
				return true;
			}
		},

		saveGlobalName() {
			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };

				this.UPDATE_FILE_FROM_API(fd);
				Object.assign(this.FILES[this.editedIndex], this.editedItem);
				this.close();
			}
		},

		downloadFile(item) {
			this.DOWNLOAD_FILE_FROM_API(item);
		},

		downloadFileThumbnail(item) {
			this.DOWNLOAD_THUMBNAIL_FILE_FROM_API(item);
		}
	}
};
</script>
