import axios from "axios";

export default {
	state: {
		items: [],
		itemPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		}, // pagination
	},
	mutations: {
		SET_ITEMS_TO_STATE: (state, items) => {
			state.items = items;
		},
		// set metadata for pagination
		SET_ITEMS_PAGINATION_TO_STATE: (state, response) => {
			state.itemPagination.page = response.page;
			state.itemPagination.rowsPerPage = response.per_page;
			state.itemPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of items
		GET_ITEMS_BY_SEARCH({ commit }, params) {
			let meta = '';

			params.meta ? meta = 'include_metadata=enable&' : meta = '';

			return axios(`/search
			?${ meta }page=${ params.page }
			&per_page=${ params.rowsPerPage }
			&scope=${ params.scope }
			&q=${ encodeURIComponent(params.search) }`,
			{
				method: "GET"
			})
			.then(roles => {
				commit("SET_ITEMS_TO_STATE", roles.data.items);

				// commit(
				// 	"SET_ITEMS_PAGINATION_TO_STATE",
				// 	roles.data._metadata.pagination
				// );
			})
			.catch(error => {
				console.log(error); // debug
			});
		},
	},
	getters: {
		ITEMS(state) {
			return state.items;
		},
		ITEMS_PAGINATION(state) {
			return state.itemPagination;
		}
	}
};
