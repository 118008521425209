<template>
	<div>
		<div v-for="category in items" :key="category.id">
			{{ category.name }}
			<span style="float: right">{{ category.value }}%</span>
			<br />
			<v-progress-linear
				:value="category.value"
				height="10"
				striped
				rounded
				color="primary"
				class="mb-3"
			></v-progress-linear>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array
		}
	}
};
</script>
