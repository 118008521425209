<template>
	<v-data-table
		:loading="loading"
		loading-text="Пожалуйста подождите..."
		:headers="headers"
		:items="POLLS"
		:footer-props="{
			itemsPerPageOptions: [5, 10, 15, 30],
			itemsPerPageText: 'Строк на странице',
			itemsPerPageAllText: 'Все',
			pageText: '{0} - {1} из {2}'
		}"
		:options.sync="pagination"
		:server-items-length="pagination.totalItems"
		@update:options="initialize"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Опросы</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-spacer></v-spacer>
				<v-select
					:items="categories"
					v-model="filterCategoryId"
					item-value="id"
					item-text="name"
					label="Категория"
					dense
					outlined
					hide-details
					class="sections-sort"
					@change="toggleFilterCategory"
				></v-select>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-switch
					v-model="filterPublish"
					label="Опубликованные"
					hide-details
					@change="toggleFilterPublish"
				></v-switch>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-dialog v-model="dialog" scrollable max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
							Создать опрос
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>
						<v-divider></v-divider>

						<v-card-text class="pt-5">
							<v-text-field
								color="info"
								v-model="editedItem.title"
								label="Заголовок *"
								:rules="[rules.required]"
								required
								outlined
								dense
							></v-text-field>
							<v-select
								:items="categories"
								color="info"
								item-color="info"
								v-model="editedItem.category_id"
								item-value="id"
								item-text="name"
								label="Категория *"
								:rules="[rules.required]"
								required
								outlined
								dense
							></v-select>
							<v-checkbox
								color="info"
								v-model="editedItem.is_published"
								label="Опубликовать"
								hide-details
							></v-checkbox>
						</v-card-text>

						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="close"> Отмена </v-btn>
							<v-btn
								color="primary"
								@click="save"
								:disabled="!validationСheck()"
							>
								Сохранить
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogShow" max-width="500px">
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ editedItem.title }}</span>
						</v-card-title>
						<v-card-text> ... </v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="closeShow">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<v-card-title>
							<span class="text-h5">Удаление</span>
						</v-card-title>
						<v-card-text>
							Вы действительно хотите удалить этот опрос?
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="deleteItemConfirm">
								Да
							</v-btn>
							<v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item.actions="{ item }">
			<router-link :to="`/polls/${item.id}/questions`" class="mr-2 i-link">
				<v-btn x-small color="info">Вопросы</v-btn>
			</router-link>
			<v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
				mdi-eye
			</v-icon>
			<v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
				mdi-pencil
			</v-icon>
			<v-icon dense color="secondary" @click="deleteItem(item)">
				mdi-delete
			</v-icon>
		</template>
		<template v-slot:no-data>
			<v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
		</template>
	</v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		filterPublish: true,
		filterCategoryId: null,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Заголовок", value: "title" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле"
		},
		// POLLS: [],
		editedIndex: -1,
		editedItem: {
			title: null,
			category_id: null,
			is_published: false
		},
		defaultItem: {
			title: null,
			category_id: null,
			is_published: false
		},
		paginationSetter: {},
		categories: []
		// search: null
	}),

	computed: {
		...mapGetters(["POLLS", "DIRECTORIES", "POLLS_PAGINATION"]),

		pagination: {
			get() {
				return this.POLLS_PAGINATION;
			},
			set() {
				this.paginationSetter = this.POLLS_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1 ? "Создать опрос" : "Обновить опрос";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_POLLS_FROM_API",
			"CREATE_POLL_TO_API",
			"UPDATE_POLL_FROM_API",
			"DELETE_POLL_FROM_API",
			// v-select
			"GET_DIRECTORIES_FROM_API"
		]),

		initialize() {
			this.loading = true;

			this.pagination.is_published = this.filterPublish;

			this.GET_DIRECTORIES_FROM_API().then(() => {
				this.categories = this.DIRECTORIES.polls.categories;

				if (!this.filterCategoryId) {
					this.filterCategoryId = this.categories[0].id;
					this.pagination.category_id = this.categories[0].id;
				}

				this.GET_POLLS_FROM_API(this.pagination).then(
					() => (this.loading = false)
				);
			});
		},

		showItem(item) {
			this.editedIndex = this.POLLS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.POLLS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.POLLS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_POLL_FROM_API(this.editedItem);
			this.POLLS.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };
				delete fd.can_send_results;
				delete fd.can_get_results;

				for (let key in fd) if (!fd[key]) delete fd[key];

				this.UPDATE_POLL_FROM_API(fd);
				Object.assign(this.POLLS[this.editedIndex], this.editedItem);
			} else {
				let fd = { ...this.editedItem };

				for (let key in fd) if (!fd[key]) delete fd[key];

				this.CREATE_POLL_TO_API(fd);
			}
			this.close();
		},

		toggleFilterCategory(id) {
			this.loading = true;
			this.paginationSetter.category_id = id;

			this.GET_POLLS_FROM_API(this.pagination).then(
				() => (this.loading = false)
			);
		},

		toggleFilterPublish() {
			this.loading = true;

			this.pagination.is_published = this.filterPublish;

			this.GET_POLLS_FROM_API(this.pagination).then(
				() => (this.loading = false)
			);
		},

		validationСheck() {
			if (
				this.rules.required(this.editedItem.title) == true &&
				this.rules.required(this.editedItem.category_id) == true
			) {
				return true;
			}
		}
	}
};
</script>

<style scoped>
.i-link {
	text-decoration: none;
}
</style>
