<template>
	<v-data-table
		:loading="loading"
		loading-text="Пожалуйста подождите..."
		:headers="headers"
		:items="SUBCATEGORIES"
		:footer-props="{
			itemsPerPageOptions: [1, 10, 15, 30],
			itemsPerPageText: 'Строк на странице',
			itemsPerPageAllText: 'Все',
			pageText: '{0} - {1} из {2}'
		}"
		:options.sync="pagination"
		:server-items-length="pagination.totalItems"
		@update:options="initialize"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Подкатегории</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" scrollable max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
							Создать подкатегорию
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>
						<v-divider></v-divider>

						<v-card-text class="pt-5">
							<v-text-field
								color="info"
								v-model="editedItem.name"
								label="Название"
								outlined
								dense
							></v-text-field>
							<v-select
								:items="CATEGORIES"
								color="info"
								item-color="info"
								v-model="editedItem.category_id"
								item-value="id"
								item-text="name"
								label="Категория *"
								dense
								outlined
							></v-select>
						</v-card-text>
						<v-divider></v-divider>

						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="close">
								Отмена
							</v-btn>
							<v-btn
								color="primary"
								@click="save"
								:disabled="!validationСheck()"
							>
								Сохранить
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogShow" max-width="500px">
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ editedItem.name }}</span>
						</v-card-title>
						<v-card-text class="pt-5"> </v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="closeShow">
								OK
							</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title>
							<span class="text-h5">Удаление</span>
						</v-card-title>
						<v-card-text class="pt-5">
							Вы действительно хотите удалить эту подкатегорию?
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="deleteItemConfirm">
								Да
							</v-btn>
							<v-btn color="primary" dark @click="closeDelete">Отмена</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item.actions="{ item }">
			<v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
				mdi-eye
			</v-icon>
			<v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
				mdi-pencil
			</v-icon>
			<v-icon dense color="secondary" @click="deleteItem(item)">
				mdi-delete
			</v-icon>
		</template>
		<template v-slot:no-data>
			<v-btn color="primary" dark @click="initialize">
				Сброс
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Название", value: "name" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле"
		},
		// SUBCATEGORIES: [],
		editedIndex: -1,
		editedItem: {
			name: null,
			category_id: 0
		},
		defaultItem: {
			name: null,
			category_id: 0
		},
		paginationSetter: null
		// search: null
	}),

	computed: {
		...mapGetters(["SUBCATEGORIES", "CATEGORIES", "SUBCATEGORIES_PAGINATION"]),

		pagination: {
			get() {
				return this.SUBCATEGORIES_PAGINATION;
			},
			set() {
				this.paginationSetter = this.SUBCATEGORIES_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1
				? "Создать подкатегорию"
				: "Обновить подкатегорию";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_SUBCATEGORIES_FROM_API",
			"CREATE_SUBCATEGORY_TO_API",
			"UPDATE_SUBCATEGORY_FROM_API",
			"DELETE_SUBCATEGORY_FROM_API",
			// v-select
			"GET_CATEGORIES_FROM_API"
		]),

		initialize(props) {
			this.loading = true;
			this.GET_SUBCATEGORIES_FROM_API({
				pagination: props // pagination
			}).then(() => (this.loading = false));
			this.GET_CATEGORIES_FROM_API(null);
		},

		showItem(item) {
			this.editedIndex = this.SUBCATEGORIES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.SUBCATEGORIES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.SUBCATEGORIES.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_SUBCATEGORY_FROM_API(this.editedItem);
			this.SUBCATEGORIES.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };

				this.UPDATE_SUBCATEGORY_FROM_API(fd);
				Object.assign(this.SUBCATEGORIES[this.editedIndex], this.editedItem);
			} else {
				let fd = { ...this.editedItem };

				this.CREATE_SUBCATEGORY_TO_API(fd);
			}
			this.close();
		},

		validationСheck() {
			if (
				this.rules.required(this.editedItem.category_id) == true &&
				this.rules.required(this.editedItem.name) == true
			) {
				return true;
			}
		}
	}
};
</script>
