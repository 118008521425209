import axios from "axios";

export default {
	state: {
		subcategories: [],
		subcategoryStatus: {}, // response obj
		subcategoryPagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0
		} // pagination
	},
	mutations: {
		SET_SUBCATEGORIES_TO_STATE: (state, subcategories) => {
			state.subcategories = subcategories;
		},
		SET_SUBCATEGORY_TO_STATE: (state, subcategory) => {
			state.subcategories.push(subcategory);
		},
		UPDATE_SUBCATEGORY_FROM_STATE: (state, subcategory) => {
			const index = state.subcategories.findIndex(r => r.id === subcategory.id);
			if (index !== -1) state.subcategories.splice(index, 1, subcategory);
		},
		DELETE_SUBCATEGORY_FROM_STATE: (state, subcategory) => {
			state.subcategories = state.subcategories.filter(
				r => subcategory.id !== r.id
			);
		},
		// set response
		SET_SUBCATEGORY_RESPONSE_TO_STATE: (state, response) => {
			state.subcategoryStatus = response;
		},
		// set metadata for pagination
		SET_SUBCATEGORIES_PAGINATION_TO_STATE: (state, response) => {
			state.subcategoryPagination.page = response.page;
			state.subcategoryPagination.rowsPerPage = response.per_page;
			state.subcategoryPagination.totalItems = response.items;
		}
	},
	actions: {
		// return a list of subcategories
		GET_SUBCATEGORIES_FROM_API({ commit }, params) {

			let concat = null
			params !== null ? concat = `?include_metadata=enable&page=${ params.pagination.page }&per_page=${ params.pagination.itemsPerPage }` : concat = '';

			return axios(`/directories/subcategories${ concat }`, {
				method: "GET"
			})
				.then(subcategories => {
					commit("SET_SUBCATEGORIES_TO_STATE", subcategories.data.items);

					if (concat) {
						commit(
							"SET_SUBCATEGORIES_PAGINATION_TO_STATE",
							subcategories.data._metadata.pagination
						);
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
				});
		},
		// create new subcategory
		CREATE_SUBCATEGORY_TO_API({ commit }, subcategory) {
			return axios("/directories/subcategories", {
				method: "POST",
				data: subcategory
			})
				.then(response => {
					if (response.status === 201) {
						commit("SET_SUBCATEGORY_TO_STATE", response.data);

						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Подкатегория успешно добавлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 400) {
						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.data.code === 401) {
						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// update subcategory
		UPDATE_SUBCATEGORY_FROM_API({ commit }, subcategory) {
			return axios("/directories/subcategories/" + subcategory.id, {
				method: "PUT",
				data: subcategory
			})
				.then(response => {
					if (response.status === 201) {
						commit("UPDATE_SUBCATEGORY_FROM_STATE", response.data);

						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Подкатегория успешно обновлена",
							type: "success",
							show: true
						});
					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 400) {
						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Некорректный запрос",
							type: "error",
							show: true
						});
					} else if (error.response.data.code === 401) {
						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		},
		// delete subcategory
		DELETE_SUBCATEGORY_FROM_API({ commit }, subcategory) {
			return axios("/directories/subcategories/" + subcategory.id, {
				method: "DELETE"
			})
				.then(response => {
					if (response.status === 204) {
						commit("DELETE_SUBCATEGORY_FROM_STATE", subcategory);

						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Подкатегория успешно удалена",
							type: "success",
							show: true
						});

					}
				})
				.catch(error => {
					console.log(error.response.data); // debug
					if (error.response.data.code === 401) {
						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Пользователь не авторизован",
							type: "error",
							show: true
						});
					} else {
						commit("SET_SUBCATEGORY_RESPONSE_TO_STATE", {
							text: "Непредвиденная ошибка",
							type: "error",
							show: true
						});
					}
				});
		}
	},
	getters: {
		SUBCATEGORIES(state) {
			return state.subcategories;
		},
		SUBCATEGORY_STATUS(state) {
			return state.subcategoryStatus;
		},
		SUBCATEGORIES_PAGINATION(state) {
			return state.subcategoryPagination;
		}
	}
};
