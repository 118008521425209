<template>
	<v-data-table
		:loading="loading"
		loading-text="Пожалуйста подождите..."
		:headers="headers"
		:items="PROGRAMS"
		:footer-props="{
			itemsPerPageOptions: [5, 10, 15, 30],
			itemsPerPageText: 'Строк на странице',
			itemsPerPageAllText: 'Все',
			pageText: '{0} - {1} из {2}'
		}"
		:options.sync="pagination"
		:server-items-length="pagination.totalItems"
		@update:options="initialize"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Программы</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" scrollable max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
							Создать программу
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>
						<v-divider></v-divider>

						<v-card-text class="pt-5">
							<v-text-field
								color="info"
								v-model="editedItem.title"
								label="Заголовок *"
								:rules="[rules.required]"
								required
								outlined
								dense
							></v-text-field>
							<v-text-field
								color="info"
								v-model="editedItem.abbreviation"
								label="Аббревиатура *"
								:rules="[rules.abbreviation, rules.required]"
								required
								outlined
								dense
							></v-text-field>
							<v-checkbox
								color="info"
								v-model="editedItem.is_published"
								label="Опубликовать *"
								hide-details
							></v-checkbox>
						</v-card-text>

						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="close"> Отмена </v-btn>
							<v-btn
								color="primary"
								@click="save"
								:disabled="!validationСheck()"
							>
								Сохранить
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogShow" max-width="500px">
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ editedItem.title }}</span>
						</v-card-title>
						<v-card-text>
							<progress-bars :items="progressBarsItems" />
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="closeShow">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<v-card-title>
							<span class="text-h5">Удаление</span>
						</v-card-title>
						<v-card-text>
							Вы действительно хотите удалить эту программу?
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="deleteItemConfirm">
								Да
							</v-btn>
							<v-btn color="primary" dark @click="closeDelete"> Отмена </v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item.actions="{ item }">
			<router-link :to="`/programs/${item.id}/lessons`" class="mr-2 i-link"
				><v-btn x-small color="info">Занятия</v-btn>
			</router-link>
			<v-icon dense class="mr-2" color="secondary" @click="showItem(item)">
				mdi-eye
			</v-icon>
			<v-icon dense class="mr-2" color="secondary" @click="editItem(item)">
				mdi-pencil
			</v-icon>
			<v-icon dense color="secondary" @click="deleteItem(item)">
				mdi-delete
			</v-icon>
		</template>
		<template v-slot:no-data>
			<v-btn color="primary" dark @click="initialize"> Сброс </v-btn>
		</template>
	</v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProgressBars from "@/components/programs/ProgressBars";

export default {
	data: () => ({
		loading: true,
		dialog: false,
		dialogShow: false,
		dialogDelete: false,
		headers: [
			// { text: "ID", value: "id" },
			{ text: "Заголовок", value: "title" },
			{ text: "Аббревиатура", value: "abbreviation" },
			{ text: "", align: "end", value: "actions", sortable: false }
		],
		rules: {
			required: value => !!value || "Обязательное поле",
			abbreviation: value => {
				const pattern = /^[a-zA-Z0-9]{0,3}$/;
				return pattern.test(value) || "Некорректная аббревиатура";
			}
		},
		// PROGRAMS: [],
		editedIndex: -1,
		editedItem: {
			title: null,
			abbreviation: null,
			is_published: false
		},
		defaultItem: {
			title: null,
			abbreviation: null,
			is_published: false
		},
		paginationSetter: {},
		progressBarsItems: []
		// search: null
	}),

	components: {
		ProgressBars
	},

	computed: {
		...mapGetters(["PROGRAMS", "PROGRAMS_PAGINATION"]),

		pagination: {
			get() {
				return this.PROGRAMS_PAGINATION;
			},
			set() {
				this.paginationSetter = this.PROGRAMS_PAGINATION;
			}
		},

		formTitle() {
			return this.editedIndex === -1
				? "Создать программу"
				: "Обновить программу";
		}
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		}
	},

	methods: {
		...mapActions([
			"GET_PROGRAMS_FROM_API",
			"CREATE_PROGRAM_TO_API",
			"UPDATE_PROGRAM_FROM_API",
			"DELETE_PROGRAM_FROM_API"
		]),

		initialize(props) {
			this.loading = true;
			this.GET_PROGRAMS_FROM_API(props).then(() => (this.loading = false));
		},

		showItem(item) {
			this.editedIndex = this.PROGRAMS.indexOf(item);
			this.editedItem = Object.assign({}, item);

			this.progressBarsItems = [];

			for (let id in this.editedItem.categories_percent) {
				this.$store.dispatch("GET_CATEGORY_FROM_API", id).then(() => {
					const tempObj = this.$store.getters.CATEGORY;
					const newObj = {
						id: id,
						name: tempObj.name,
						value: this.editedItem.categories_percent[id]
					};

					this.progressBarsItems.push(newObj);
				});
			}

			this.dialogShow = true;
		},

		editItem(item) {
			this.editedIndex = this.PROGRAMS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.PROGRAMS.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.DELETE_PROGRAM_FROM_API(this.editedItem);
			this.PROGRAMS.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeShow() {
			this.dialogShow = false;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				let fd = { ...this.editedItem };
				delete fd.categories_percent; // delete because in swagger this field not need

				for (let key in fd) if (!fd[key]) delete fd[key];

				this.UPDATE_PROGRAM_FROM_API(fd);
				Object.assign(this.PROGRAMS[this.editedIndex], this.editedItem);
			} else {
				let fd = { ...this.editedItem };

				for (let key in fd) if (!fd[key]) delete fd[key];

				this.CREATE_PROGRAM_TO_API(fd);
			}
			this.close();
		},

		validationСheck() {
			if (
				this.rules.required(this.editedItem.title) == true &&
				this.rules.required(this.editedItem.abbreviation) == true
			) {
				return true;
			}
		}
	}
};
</script>

<style scoped>
.i-link {
	text-decoration: none;
}
</style>
